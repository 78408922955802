import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { isLogged } from '@wizbii-utils/angular/stores';
import { map, take } from 'rxjs';

export const redirectToDashboardGuard: CanActivateFn = () => {
  const dashboardRoute = inject(Router).createUrlTree(['/', FEATURES_ROUTING.dashboard]);

  return inject(Store)
    .select(isLogged)
    .pipe(
      take(1),
      map((isLogged) => (isLogged ? dashboardRoute : true))
    );
};
