import { City } from '@wizbii/utils/models';

export enum EventFullSourceEnum {
  WIZBII = 'wizbii',
  FRANCE_TRAVAIL = 'france-travail',
}

export interface EventFull {
  id: string;
  slug: string;
  title: string;
  description: string;
  modality: string;
  company: Company | null;
  status: string;
  type: string;
  illustrationUrl: string | null;
  location?: City;
  startDate: string;
  endDate: string;
  eventContent: EventContent[];
  registrationSettings: RegistrationSettings;
  source: EventFullSourceEnum;
  externalId: string;
  createdAt: string;
  updatedAt: string;
  isRemote: boolean | null;
  isPhysical: boolean | null;
  inscriptionAvailable: boolean;
  typeLabel: string;
  remotePlacesLeft: number | null;
  physicalPlacesLeft: number | null;
  placesLeft: number | null;
}
export interface EventFullAlgolia {
  slug: string;
}

interface Company {
  id: string;
  name: string;
  logoUrl: string | null;
  domain: string | null;
  email: string | null;
}

interface EventContent {
  title: string;
  city: string;
  domain: string;
  contractType: string;
}

interface RegistrationSettings {
  registrationType: string;
  spotAvailableRemote: number;
  remoteRegistered: number | null;
  spotAvailablePhysical: number;
  physicalRegistered: number;
  externalUrl: string;
}
