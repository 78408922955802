import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FormFields } from '@models/partner/form-field/form-field.interface';
import { Store } from '@ngrx/store';
import { selectPartner } from '@stores/partner/partner.selectors';
import { selectProfile } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { ProfileV3WebService } from '@webservices/profile/profile.webservice';
import { Observable, combineLatest, switchMap } from 'rxjs';

export const configurableFieldsResolver: ResolveFn<Observable<FormFields>> = () => {
  const store = inject(Store);
  const profileWebService = inject(ProfileV3WebService);

  return combineLatest([selectIfDefined(store, selectProfile), selectIfDefined(store, selectPartner)]).pipe(
    switchMap(([profile, partner]) => profileWebService.getConfigurableFields(partner.id, profile.id))
  );
};
