import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { isServiceUnavailableInUserPlan } from '@stores/selectors/profile-services.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { map, take } from 'rxjs';

export const canViewProgressGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return selectIfDefined(store, isServiceUnavailableInUserPlan(SERVICES_ROUTING.drive)).pipe(
    map((hasNotAccess) =>
      hasNotAccess ? router.parseUrl(`/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.drive}`) : true
    ),
    take(1)
  );
};
