import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { allowUpsellWithoutPayment } from '@stores/feature-flags/feature-flags.selectors';
import { hasTopPlan } from '@stores/selectors/profile-plans.selector';
import { combineLatest, filter, map } from 'rxjs';

export const isAlreadyTopPlanNoRenewGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  const userIsTopPlan$ = store.select(hasTopPlan).pipe(
    filter((hasTopPlan) => hasTopPlan !== undefined),
    map((hasTopPlan) => (hasTopPlan === null ? false : hasTopPlan))
  );

  return combineLatest([userIsTopPlan$, store.select(allowUpsellWithoutPayment)]).pipe(
    map(([userIsTopPlan, allowUpsellWithoutPayment]) =>
      userIsTopPlan && allowUpsellWithoutPayment ? router.parseUrl(`/${FEATURES_ROUTING.dashboard}`) : true
    )
  );
};
