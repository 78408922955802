import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FAQBlock } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { partnerId } from '@stores/partner/partner.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { FAQWebservice } from '@webservices/faq/faq.webservice';
import { Observable, switchMap } from 'rxjs';

export const faqResolver: (pageId: string) => ResolveFn<Observable<FAQBlock>> = (pageId) => () => {
  const store = inject(Store);
  const faqWebservice = inject(FAQWebservice);

  return selectIfDefined(store, partnerId).pipe(switchMap((partnerIdP) => faqWebservice.getFaq(partnerIdP, pageId)));
};
