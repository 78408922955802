import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { service, service as serviceSelector } from '@stores/services/services.selector';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { isLogged } from '@wizbii-utils/angular/stores';
import { Observable, combineLatest, filter, map } from 'rxjs';

export const ServiceWithManyTabsActivateGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<true | UrlTree> => {
  const serviceId = route.params[SERVICE_ID_ROUTE_PARAM] ?? route.data.serviceId;
  const router = inject(Router);
  const store = inject(Store);

  const servicesRoute = FEATURES_ROUTING.services;
  const urlSlug = route.params.tabSlug;

  const service$ = selectIfDefined(store, serviceSelector(serviceId));
  return service$.pipe(
    map((service) => {
      const hasManyTabs = service.tabs.length > 1;
      const isSlugExist = service.tabs.some((tab) => tab.slug === urlSlug);

      return hasManyTabs && isSlugExist ? true : router.parseUrl(`/${servicesRoute}/${serviceId}`);
    })
  );
};

export const RedirectToFirstTab: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<true | UrlTree> => {
  const serviceId = route.params[SERVICE_ID_ROUTE_PARAM] ?? route.data.serviceId;

  const router = inject(Router);
  const store = inject(Store);

  const servicesRoute = FEATURES_ROUTING.services;

  const service$ = selectIfDefined(store, serviceSelector(serviceId));
  return service$.pipe(
    map((service) => {
      const firstTabServiceSlug = service.tabs[0].slug;
      return router.parseUrl(`/${servicesRoute}/${serviceId}/${firstTabServiceSlug}`);
    })
  );
};

export const ServiceWithManyTabsMatchGuard: CanMatchFn = (_route, segments) => {
  const serviceId = segments[0].path;
  const store = inject(Store);

  return combineLatest([store.select(service(serviceId)), store.select(isLogged)]).pipe(
    filter(([service, isLogged]) => (isLogged && Boolean(service)) || !isLogged),
    map(([service, _]) => (service?.tabs ?? []).length > 1)
  );
};
