import { ObendyRoute } from '@core/app-routing';
import { ConnectedRoutesGuards } from '@core/guards/connected-routes.guard';
import { CommunicationsResolver } from '@features/dashboard/resolvers/communications.resolver';
import { DashboardPageResolver } from '@features/dashboard/resolvers/dashboard-page.resolver';
import { PlanRenewBannerMessageResolver } from '@features/dashboard/resolvers/plan-renew-banner-message.resolver';
import { ShowCallToActionCardResolver } from '@features/dashboard/resolvers/show-call-to-action.resolver';
import { FEATURES_ROUTING } from '@features/features.routing';
import { LEGAL_GUARDIAN_DASHBOARD_ROUTE } from '@features/legal-guardian-dashboard/routes/legal-guardian-dashboard.routes';

const GENERIC_DASHBOARD = import('@features/dashboard/dashboard/dashboard.component');

export const DASHBOARD_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.dashboard,
  title: 'Accueil',
  showInSiteMap: true,

  loadChildren: () => [
    LEGAL_GUARDIAN_DASHBOARD_ROUTE,
    {
      path: '',
      showInSiteMap: false,
      loadComponent: async () => (await GENERIC_DASHBOARD).DashboardComponent,
      data: {
        showBreadcrumb: false,
        pageWithIncentiveBanner: true,
      },
      canActivate: ConnectedRoutesGuards,
      resolve: {
        showCallToActionCard: ShowCallToActionCardResolver,
        dashboardPage: DashboardPageResolver,
        communications: CommunicationsResolver,
        planRenewMessage: PlanRenewBannerMessageResolver,
      },
    },
  ],
};
