import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { selectProfile } from '@stores/profile/profile.selectors';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { ProfileV3WebService } from '@webservices/profile/profile.webservice';
import { map, switchMap } from 'rxjs';

export const profileInformationsGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  const profileWebService = inject(ProfileV3WebService);
  const changePlanRoute = router.createUrlTree(['/', FEATURES_ROUTING.changePlan]);
  const acceptNewCguRoutes = router.createUrlTree(['/', FEATURES_ROUTING.acceptNewCGUComponent]);
  const revokedMinorRoutes = router.createUrlTree(['/', FEATURES_ROUTING.revokedMinor]);

  return selectOnceIfDefined(store, selectProfile).pipe(
    switchMap(({ id }) => profileWebService.getProfileInformation(id)),
    map(({ headers }) => ({
      isRenew: headers.get('X-Display-Renew-Plan') === 'true',
      shouldReviewCGU: headers.get('X-Should-Review-Cgu') === 'true',
      isMinorRevoked: headers.get('X-Access-Revoked') === 'true',
    })),
    map(({ isRenew, isMinorRevoked, shouldReviewCGU }) => {
      if (isRenew) {
        return changePlanRoute;
      }

      if (shouldReviewCGU) {
        return acceptNewCguRoutes;
      }

      if (isMinorRevoked) {
        return revokedMinorRoutes;
      }

      return true;
    })
  );
};
