import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { isServiceUnavailableInUserPlan } from '@stores/selectors/profile-services.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { map, take } from 'rxjs';

export const hasAccessToDriveMatcher: CanMatchFn = () => {
  const store = inject(Store);

  return selectIfDefined(store, isServiceUnavailableInUserPlan(SERVICES_ROUTING.drive)).pipe(
    map((hasNotAccess) => !hasNotAccess),
    take(1)
  );
};

export const hasNoAccessToDriveMatcher: CanMatchFn = () => {
  const store = inject(Store);

  return selectIfDefined(store, isServiceUnavailableInUserPlan(SERVICES_ROUTING.drive)).pipe(map(Boolean), take(1));
};
