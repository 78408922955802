import { inject } from '@angular/core';
import { ObendyRoute } from '@core/app-routing';
import { requestServiceAuthorizationToRedirectGuard } from '@core/guards/request-authorization-to-redirect.guard';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { trainingResolver } from '@features/services/trainings/resolvers/training.resolver';
import { TRAININGS_ROUTING } from '@features/services/trainings/trainings/trainings.routing';
import { Store as NgxrStore } from '@ngrx/store';
import { isServiceUnavailableInUserPlan } from '@stores/selectors/profile-services.selectors';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';
import { service } from '@stores/services/services.selector';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { Observable, map, switchMap } from 'rxjs';

export const WIZBII_FORMATION_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.trainings,
  title: serviceTitleResolver,
  showInSiteMap: false,
  data: {
    serviceId: INTERNAL_SERVICES_IDS.trainings,
  },
  canActivate: [updateServiceConfigurationMiddleware],

  loadChildren: () => [
    {
      path: TRAININGS_ROUTING.trainings,
      title: serviceTabTitleResolver,
      showInSiteMap: false,
      loadComponent: () =>
        import('@features/services/trainings/trainings/trainings.component').then((cmp) => cmp.TrainingsComponent),
      resolve: {
        isLocked: (): Observable<boolean> => {
          const store = inject(NgxrStore);
          return selectIfDefined(store, service(SERVICES_ROUTING.trainings)).pipe(
            map((service) => service.id),
            switchMap((id) => selectIfDefined(store, isServiceUnavailableInUserPlan(id)))
          );
        },
      },
    },

    {
      path: `${TRAININGS_ROUTING.trainings}/${TRAININGS_ROUTING.trainingSlug}`,
      showInSiteMap: false,
      canActivate: [requestServiceAuthorizationToRedirectGuard()],
      loadComponent: () =>
        import('@features/services/trainings/training-view/training-view.component').then(
          (cmp) => cmp.TrainingViewComponent
        ),
      resolve: { training: trainingResolver },
      data: {
        titlePath: 'training.title',
      },
    },

    {
      path: TRAININGS_ROUTING.tabSlug,
      title: serviceTabTitleResolver,
      showInSiteMap: false,
      resolve: {
        content: TabContentFromSlugResolver,
      },
      loadComponent: async () =>
        (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
          .ServiceWithRichContentComponent,
    },

    {
      path: '',
      showInSiteMap: false,
      redirectTo: TRAININGS_ROUTING.trainings,
      pathMatch: 'full',
    },
  ],
};
