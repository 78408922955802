import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { allowUpsellWithoutPayment } from '@stores/feature-flags/feature-flags.selectors';
import { filter, map } from 'rxjs';

export const hasNoBankingProductFeatureFlagGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return store.select(allowUpsellWithoutPayment).pipe(
    filter((res) => res !== undefined),
    map((flag) => (flag ? router.parseUrl(`/${FEATURES_ROUTING.notFound}`) : true))
  );
};
