import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { getDossiers } from '@stores/money/money.actions';
import { dossiers } from '@stores/money/money.selectors';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { userId } from '@wizbii-utils/angular/stores';
import { FibiiWebservice } from '@wizbii-utils/angular/webservices';
import { DossierStatusEnum } from '@wizbii/utils/models';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

export const dossierValidationGuard = (route: ActivatedRouteSnapshot): Observable<boolean> | boolean => {
  const store = inject(Store);
  const fibiiWebservice = inject(FibiiWebservice);

  const suggestionId = route.paramMap.get('suggestionId');

  if (!suggestionId) {
    return false;
  }

  return combineLatest([
    selectOnceIfDefined(store, dossiers).pipe(
      map((dossiers) => dossiers.find((d) => d.suggestionId === suggestionId)),
      map((dossier) => (dossier ? dossier.status === DossierStatusEnum.ToBeCompleted : false))
    ),
    selectOnceIfDefined(store, userId),
  ]).pipe(
    take(1),
    switchMap(([isToBeCompleted, id]) => {
      if (isToBeCompleted) {
        return fibiiWebservice.updateDossierStatusV2(id, suggestionId, DossierStatusEnum.ToBeValidated).pipe(
          tap(() => store.dispatch(getDossiers())),
          map(() => true)
        );
      }
      return of(true);
    })
  );
};
