import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { MONEY_DASHBOARD_ROUTING } from '@domains/money/money-dashboard/money-dashboard.routing';
import { FEATURES_ROUTING } from '@features/features.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { suggestionSetId } from '@stores/money/money.selectors';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { userId } from '@wizbii-utils/angular/stores';
import { SuggestionsWebservice } from '@wizbii-utils/angular/webservices';
import { catchError, combineLatest, map, of, switchMap } from 'rxjs';

export const hasStartedSimulationGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  const route = inject(ActivatedRoute);
  const suggestionWebservice = inject(SuggestionsWebservice);

  return route.data.pipe(
    map((data) => data.serviceId),
    switchMap((serviceId) =>
      combineLatest([selectOnceIfDefined(store, userId), selectOnceIfDefined(store, suggestionSetId(serviceId))]).pipe(
        switchMap(([id, setId]) => {
          return suggestionWebservice.getSuggestions(setId, id);
        }),
        map((suggestion) =>
          !suggestion
            ? true
            : router.parseUrl(
                `/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.money}/${MONEY_DASHBOARD_ROUTING.simulation}`
              )
        ),
        catchError(() => of(true))
      )
    )
  );
};
