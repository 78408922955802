import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getDossiers, getDossiersSuccess, init } from '@stores/money/money.actions';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { info } from '@wizbii-utils/angular/stores';
import { FibiiWebservice } from '@wizbii-utils/angular/webservices';
import { map, switchMap } from 'rxjs';

export const loadMoney = createEffect(
  (fibiiWebservice = inject(FibiiWebservice), store = inject(Store)) =>
    selectIfDefined(store, info).pipe(
      switchMap(() => fibiiWebservice.getDossiers()),
      map((dossiers) => init({ dossiers }))
    ),
  { functional: true }
);

export const getDossiersEffect = createEffect(
  (actions$ = inject(Actions), fibiiWebservice = inject(FibiiWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(getDossiers),
      switchMap(() => selectIfDefined(store, info)),
      switchMap(() => fibiiWebservice.getDossiers()),
      map((dossiers) => getDossiersSuccess({ dossiers }))
    ),
  { functional: true }
);
