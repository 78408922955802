import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { service } from '@stores/services/services.selector';
import { isLogged } from '@wizbii-utils/angular/stores';
import { combineLatest, filter, map } from 'rxjs';

export const ServiceWithOneTabMatchGuard: CanMatchFn = (_, segments) => {
  const serviceId = segments[0].path;
  const store = inject(Store);

  return combineLatest([store.select(service(serviceId)), store.select(isLogged)]).pipe(
    filter(([service, isLogged]) => (isLogged && Boolean(service)) || !isLogged),
    map(([service, _]) => (service?.tabs ?? []).length === 1)
  );
};
