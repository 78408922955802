import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { info } from '@wizbii-utils/angular/stores';
import { map, take } from 'rxjs';

export const canAccessPasswordExpired: CanActivateFn = () => {
  const router = inject(Router);

  return inject(Store)
    .select(info)
    .pipe(
      take(1),
      map((token) => ((token as any)?.['passwordExpired'] ? true : router.parseUrl('/')))
    );
};
