import { ActivatedRouteSnapshot } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { FEATURES_ROUTING } from '@features/features.routing';
import { TargetEnum } from '@loyalty-v3/libs';
import { ServiceAuthorizationContext } from '@stores/service-authorization/authorization.reducer';

/**
 * @param successfulExitRouterLinkOrUrl if not provided, defaults to `['/', FeaturesRouting.redirect, serviceId]` with target `_blank`.
 */
export const AuthorizationContextResolver =
  (successfulExitRouterLinkOrUrl?: string[]) =>
  (route: ActivatedRouteSnapshot): ServiceAuthorizationContext => {
    const serviceId = route.params[SERVICE_ID_ROUTE_PARAM] ?? (route.data.serviceId as string);

    if (successfulExitRouterLinkOrUrl) {
      return { serviceId, successfulExitRouterLinkOrUrl };
    }

    return {
      serviceId,
      successfulExitRouterLinkOrUrl: ['/', FEATURES_ROUTING.redirect, serviceId],
      target: TargetEnum.blank,
    };
  };
