import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { hasPayment } from '@stores/partner/partner.selectors';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { map } from 'rxjs';

export const platformHasPaymentGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const store = inject(Store);

  return selectOnceIfDefined(store, hasPayment).pipe(
    map((hasPayment) => (hasPayment ? true : router.parseUrl(route.data.guardRouteToRedirect as string)))
  );
};
