import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { NotificationsService } from '@core/services/notifications.service';
import { FEATURES_ROUTING } from '@features/features.routing';
import { DRIVE_ROUTING } from '@features/services/drive/drive.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Lesson } from '@models/drive';
import { NotificationType } from '@models/notification/notification';
import { LessonWebservice } from '@webservices/drive';
import { catchNotFound } from '@wizbii-utils/angular/core';
import { Observable, catchError, throwError } from 'rxjs';

export const lessonResolver: ResolveFn<Observable<Lesson>> = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const notificationsService = inject(NotificationsService);

  return inject(LessonWebservice)
    .get(route.params.lessonId)
    .pipe(
      catchNotFound(router, ['/', FEATURES_ROUTING.notFound]),
      catchError((error) => {
        if (error.error.status === 403 || error.status === 403) {
          router.navigate([FEATURES_ROUTING.services, SERVICES_ROUTING.drive, DRIVE_ROUTING.lessons]);
        }

        return throwError(() => {
          notificationsService.createNotification({
            title: 'Mince !',
            text: `Vous n'avez pas accès à cette page`,
            type: NotificationType.danger,
            date: '',
          });
          return new Error("Vous n'avez pas accès à la page lesson");
        });
      })
    );
};
