import { ObendyRoutes } from '@core/app-routing';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { FEATURES_ROUTING } from '@features/features.routing';
import { BIG_FIVE_ROUTING } from '@features/services/big-five/big-five.routing';
import { canAccessBigFiveAbout } from '@features/services/big-five/guards/can-access-big-five-about.guard';
import { canAccessBigFiveResult } from '@features/services/big-five/guards/can-access-big-five-result.guard';
import { bigFiveResultsResolver } from '@features/services/big-five/resolvers/big-five-answers.resolver';
import { bigFiveQuestionsResolver } from '@features/services/big-five/resolvers/big-five-questions.resolver';
import { AuthorizationContextResolver } from '@features/services/external-service/resolvers/authorization-context.resolver';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { DeactivationGuarded } from '@models/can-deactivate';
import { provideState } from '@ngrx/store';
import { bigFiveFeatureKey, bigFiveReducer } from '@stores/big-five/big-five.reducer';
import { Observable } from 'rxjs';

export const BIG_FIVE_ROUTES: ObendyRoutes = [
  {
    path: `${SERVICES_ROUTING.bigFive}/${BIG_FIVE_ROUTING.form}`,
    providers: [provideState({ name: bigFiveFeatureKey, reducer: bigFiveReducer })],
    title: serviceTitleResolver,
    showInSiteMap: false,
    canActivate: [updateServiceConfigurationMiddleware],
    data: {
      showMenu: false,
      showFooter: false,
      serviceId: SERVICES_ROUTING.bigFive,
    },
    resolve: {
      questions: bigFiveQuestionsResolver,
      authorizationContext: AuthorizationContextResolver([
        '/',
        FEATURES_ROUTING.services,
        SERVICES_ROUTING.bigFive,
        BIG_FIVE_ROUTING.form,
      ]),
    },
    canDeactivate: [(comp: DeactivationGuarded): Observable<boolean> => comp.canDeactivate()],
    loadComponent: () =>
      import('@features/services/big-five/big-five-form/big-five-form.component').then(
        (cmp) => cmp.BigFiveFormComponent
      ),
  },

  {
    path: SERVICES_ROUTING.bigFive,
    providers: [provideState({ name: bigFiveFeatureKey, reducer: bigFiveReducer })],
    title: serviceTitleResolver,
    showInSiteMap: false,

    loadChildren: () => [
      {
        path: '',
        showInSiteMap: false,
        loadComponent: () =>
          import('@features/services/big-five/big-five.component').then((cmp) => cmp.BigFiveComponent),
        loadChildren: () => [
          {
            path: BIG_FIVE_ROUTING.about,
            canActivate: [canAccessBigFiveAbout],
            showInSiteMap: false,
            loadComponent: () =>
              import('@features/services/big-five/big-five-about/big-five-about.component').then(
                (cmp) => cmp.BigFiveAboutComponent
              ),
          },

          {
            path: BIG_FIVE_ROUTING.result,
            showInSiteMap: false,
            canActivate: [canAccessBigFiveResult],
            resolve: {
              results: bigFiveResultsResolver,
            },
            loadComponent: () =>
              import('@features/services/big-five/big-five-result/big-five-result.component').then(
                (cmp) => cmp.BigFiveResultComponent
              ),
          },

          {
            path: BIG_FIVE_ROUTING.tabSlug,
            title: serviceTabTitleResolver,
            showInSiteMap: false,
            resolve: {
              content: TabContentFromSlugResolver,
            },
            loadComponent: async () =>
              (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
                .ServiceWithRichContentComponent,
          },

          {
            path: '',
            showInSiteMap: false,
            redirectTo: BIG_FIVE_ROUTING.about,
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
];
