import { ObendyRoute } from '@core/app-routing';
import { requestServiceAuthorizationToRedirectGuard } from '@core/guards/request-authorization-to-redirect.guard';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { accommodationResolver } from '@features/services/housing/housing-view/resolver/accomodation.resolver';
import { HOUSING_ROUTING } from '@features/services/housing/housing.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';

export const WIZBII_HOME_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.home,
  title: serviceTitleResolver,
  showInSiteMap: false,
  data: {
    serviceId: INTERNAL_SERVICES_IDS.housing,
  },
  canActivate: [updateServiceConfigurationMiddleware],

  loadChildren: () => [
    {
      path: HOUSING_ROUTING.accomodation,
      title: serviceTabTitleResolver,
      showInSiteMap: false,
      loadComponent: () =>
        import('@features/services/housing/housing/housing.component').then((cmp) => cmp.WizbiiHomeComponent),
    },

    {
      path: `${HOUSING_ROUTING.accomodation}/${HOUSING_ROUTING.accomodationSlug}`,
      showInSiteMap: false,
      canActivate: [requestServiceAuthorizationToRedirectGuard()],
      loadComponent: () =>
        import('@features/services/housing/housing-view/housing-view.component').then(
          (cmp) => cmp.HousingViewComponent
        ),
      resolve: { accommodation: accommodationResolver },
      data: { partnerId: (window as any).partner?.id, titlePath: 'accommodation.title' },
    },

    {
      path: `${HOUSING_ROUTING.accomodation}/${HOUSING_ROUTING.accomodationSlug}/${HOUSING_ROUTING.viewPictureDetails}`,
      showInSiteMap: false,
      loadComponent: () =>
        import('@features/services/housing/housing-view-pictures/housing-view-pictures.component').then(
          (cmp) => cmp.HousingViewPicturesComponent
        ),
      resolve: {
        accommodation: accommodationResolver,
      },
      data: {
        titlePath: 'accommodation.title',
      },
    },

    {
      path: HOUSING_ROUTING.tabSlug,
      title: serviceTabTitleResolver,
      showInSiteMap: false,
      resolve: {
        content: TabContentFromSlugResolver,
      },
      loadComponent: async () =>
        (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
          .ServiceWithRichContentComponent,
    },

    {
      path: '',
      showInSiteMap: false,
      redirectTo: HOUSING_ROUTING.accomodation,
      pathMatch: 'full',
    },
  ],
};
