import { createSelector } from '@ngrx/store';
import { allowUpsellWithoutPayment } from '@stores/feature-flags/feature-flags.selectors';
import { hasPayment } from '@stores/partner/partner.selectors';
import { isLegalGuardian } from '@stores/profile/profile.selectors';
import { hasTopPlan } from '@stores/selectors/profile-plans.selector';

export const platformHasUpsell = createSelector(
  hasTopPlan,
  allowUpsellWithoutPayment,
  isLegalGuardian,
  hasPayment,
  (hasTopPlan, platformAllowsUpsellWithoutPayment, isLegalGuardian, hasPayment) => {
    if (hasTopPlan === undefined || allowUpsellWithoutPayment === undefined || isLegalGuardian === undefined)
      return undefined;

    // cas PassJeune freemium
    if (platformAllowsUpsellWithoutPayment && hasTopPlan) return true;

    // cas freemium / premium (+ renouvellement)
    if (hasTopPlan !== null) return true;

    // cas RL avec paiement pour les enfants
    if (isLegalGuardian && hasPayment) return true;

    // cas monoplan / juxtaposition / RL no payment
    return false;
  }
);
