import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { LEGAL_PAGES_ROUTING } from '@features/legal-pages/legal-pages.routing';

export const legalPageCanActivate: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);

  const type = route.params.type ?? route.data.type;

  if (!Object.values(LEGAL_PAGES_ROUTING).includes(type)) {
    router.navigate(['/', FEATURES_ROUTING.notFound], { skipLocationChange: true });
    return router.parseUrl(`/${FEATURES_ROUTING.notFound}`);
  }

  return true;
};
