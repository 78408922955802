import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { selectPartner } from '@stores/partner/partner.selectors';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { map } from 'rxjs';

export const hasSignupGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return selectOnceIfDefined(store, selectPartner).pipe(
    map((partner) => (!partner.partnerConfiguration.signUpMethod ? router.parseUrl(`/${FEATURES_ROUTING.sign}`) : true))
  );
};
