import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Customer } from '@models/onboarding/onboarding.model';
import { Store } from '@ngrx/store';
import { selectPartner } from '@stores/partner/partner.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { CustomerV3WebService } from '@webservices/customer/customer.webservice';
import { OnboardingWebservice } from '@webservices/onboarding/onboarding.webservice';
import { Observable, combineLatest, switchMap } from 'rxjs';

export const customerResolver: ResolveFn<Observable<Customer> | null> = (route: ActivatedRouteSnapshot) => {
  const customerWebservice = inject(CustomerV3WebService);
  const onboardingWebservice = inject(OnboardingWebservice);
  const store = inject(Store);

  const activationToken = route.queryParamMap.get('activation-token');
  const capsuleToken = route.queryParamMap.get('capsule-token');

  if (activationToken) {
    return selectIfDefined(store, selectPartner).pipe(
      switchMap((partner) => customerWebservice.get({ value: activationToken, partner: partner.id }))
    );
  }

  if (capsuleToken) {
    return combineLatest([
      selectIfDefined(store, selectPartner),
      onboardingWebservice.getCustomerIdFromCapsuleToken(capsuleToken),
    ]).pipe(
      switchMap(([partnerId, customerId]) => customerWebservice.get({ value: customerId, partner: partnerId.id }))
    );
  }

  return null;
};
