import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ServicesPage } from '@models/services/services-page';
import { Store } from '@ngrx/store';
import { partnerId } from '@stores/partner/partner.selectors';
import { ServicesV3WebService } from '@webservices/services/services.webservice';
import { filter, switchMap } from 'rxjs';

export const ServicesPageResolver: ResolveFn<ServicesPage> = () => {
  const servicesWebservice = inject(ServicesV3WebService);
  const partnerId$ = inject(Store).select(partnerId).pipe(filter(Boolean));

  return partnerId$.pipe(switchMap((partnerId) => servicesWebservice.getServicesPage(partnerId)));
};
