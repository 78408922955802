import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Serie } from '@models/drive';
import { SerieWebservice } from '@webservices/drive';
import { catchNotFound } from '@wizbii-utils/angular/core';
import { Observable, switchMap } from 'rxjs';

export const randomSerieResolver: ResolveFn<Observable<Serie>> = (_: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const serieWebservice = inject(SerieWebservice);

  return serieWebservice.getRandomSerieId().pipe(
    switchMap((serieId) => serieWebservice.get(serieId)),
    catchNotFound(router, ['/', FEATURES_ROUTING.notFound])
  );
};
