import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { StrapiService } from '@wizbii-utils/angular/webservices';
import {
  BlogCategoryCollectionFlat,
  BlogTypeEnum,
  LangEnum,
  deserializeBlogCategoryCollection,
} from '@wizbii/utils/models';
import { Observable, map } from 'rxjs';

export const strapiBlogCategoriesArticlesResolver: (
  strapiUrl: string
) => ResolveFn<Observable<BlogCategoryCollectionFlat>> = (strapiUrl: string) => () => {
  return inject(StrapiService)
    .getCategories(LangEnum.fr, BlogTypeEnum.Articles, strapiUrl, {
      sortBy: { publishedAt: 'desc' },
      deepness: 3,
    })
    .pipe(map(deserializeBlogCategoryCollection));
};
