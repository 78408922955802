import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { Mission } from '@models/mission/mission';
import { WizbiiEngagementWebservice } from '@webservices/engagement/engagement.webservice';
import { catchNotFound } from '@wizbii-utils/angular/core';
import { map } from 'rxjs';

export const missionResolver: ResolveFn<Mission> = (route) => {
  const wizbiiEngagementWebservice = inject(WizbiiEngagementWebservice);
  const router = inject(Router);

  const formatHtmlBreakspace = (stringToFormat: string): string => stringToFormat.replace(/\\n|\n/g, '<br>');

  return wizbiiEngagementWebservice.getMission(route.paramMap.get('missionId') ?? '').pipe(
    map((data: Mission) => {
      return {
        ...data,
        description: formatHtmlBreakspace(data.description ?? ''),
        organization: {
          ...data.organization,
          description: formatHtmlBreakspace(data.organization?.description ?? ''),
          name: data.organization?.name ?? '',
        },
      };
    }),
    catchNotFound(router, ['404'])
  );
};
