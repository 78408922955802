import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { LegalPagesRoutingType } from '@features/legal-pages/legal-pages.routing';
import { LegalMention, LegalPagesPathToType } from '@models/legals/legals.model';
import { Store } from '@ngrx/store';
import { legalPage, selectLegalPages } from '@stores/legal-pages/legal-pages.selectors';
import { filter, switchMap, tap } from 'rxjs';

export const legalPageResolver: ResolveFn<LegalMention | undefined> = (route) => {
  const store = inject(Store);
  const router = inject(Router);

  const type = route.params.type ?? route.data.type;

  return store.select(selectLegalPages).pipe(
    filter((legalPages) => legalPages.length > 0),
    switchMap(() => store.select(legalPage(LegalPagesPathToType[type as LegalPagesRoutingType]))),
    tap((legalPage) => {
      if (legalPage) {
        return;
      }

      router.navigate(['/', FEATURES_ROUTING.notFound], { skipLocationChange: true });
    })
  );
};
