import { ObendyRoute } from '@core/app-routing';
import { requestServiceAuthorizationToRedirectGuard } from '@core/guards/request-authorization-to-redirect.guard';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { strapiBlogArticleResolver } from '@domains/services/blog/resolvers/article/blog-article.resolver';
import { blogServiceResolver } from '@domains/services/blog/resolvers/articles-list/service.resolver';
import { environment } from '@environment';
import { BLOGS_ROUTING } from '@features/services/blogs/blogs.routing';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';

export const WIZBII_TIPS_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.tips,
  title: serviceTitleResolver,
  showInSiteMap: false,
  canActivate: [updateServiceConfigurationMiddleware],
  data: {
    serviceId: INTERNAL_SERVICES_IDS.tips,
  },
  loadChildren: () => [
    {
      path: BLOGS_ROUTING.articles,
      showInSiteMap: false,
      data: {
        blogServiceId: SERVICES_ROUTING.tips,
        algoliaArticleIndex: environment.algolia.index.wizbiiTipsArticles,
        algoliaCategoryIndex: environment.algolia.index.wizbiiTipsArticlesCategories,
      },
      resolve: {
        service: blogServiceResolver,
      },
      loadComponent: () =>
        import('@features/services/blogs/articles-list-routed/articles-list-routed.component').then(
          (cmp) => cmp.ArticlesListRoutedComponent
        ),
    },
    {
      path: `${BLOGS_ROUTING.articles}/${BLOGS_ROUTING.blogArticleSlug}`,
      showInSiteMap: false,
      resolve: {
        article: strapiBlogArticleResolver(environment.strapi.tipsApi),
      },
      loadComponent: () =>
        import('@domains/services/blog/article-wrapper/article-wrapper.component').then(
          (cmp) => cmp.BlogArticleWrapperComponent
        ),
      canActivate: [requestServiceAuthorizationToRedirectGuard()],
      data: {
        displayCategories: false,
        serviceRouting: SERVICES_ROUTING.tips,
        strapiUrl: environment.strapi.tipsApi,
        algoliaArticleIndex: environment.algolia.index.wizbiiTipsArticles,
        titlePath: 'article.title',
      },
    },
    {
      path: BLOGS_ROUTING.tabSlug,
      title: serviceTabTitleResolver,
      showInSiteMap: false,
      resolve: {
        content: TabContentFromSlugResolver,
      },
      loadComponent: async () =>
        (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
          .ServiceWithRichContentComponent,
    },
    {
      path: '',
      pathMatch: 'full',
      showInSiteMap: false,
      redirectTo: BLOGS_ROUTING.articles,
    },
  ],
};
