import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { FEATURES_ROUTING } from '@features/features.routing';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';

export const isNotInternalService: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const serviceId = route.paramMap.get(SERVICE_ID_ROUTE_PARAM);

  if (!serviceId) return false;

  const serviceRoute = inject(Router).createUrlTree(['/', FEATURES_ROUTING.services, serviceId]);
  const isNotInternalService = !Object.values<string>(INTERNAL_SERVICES_IDS).includes(serviceId);

  return isNotInternalService || serviceRoute;
};
