import { ObendyRoute } from '@core/app-routing';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { EDFLEX_ROUTING } from '@features/services/edflex/edflex.routing';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { SERVICES_ROUTING } from '@features/services/services.routing';

export const EDFLEX_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.edflex,
  showInSiteMap: false,
  title: serviceTitleResolver,
  data: {
    serviceId: SERVICES_ROUTING.edflex,
  },
  canActivate: [updateServiceConfigurationMiddleware],

  loadChildren: () => [
    {
      path: EDFLEX_ROUTING.formations,
      showInSiteMap: false,
      loadComponent: () => import('@features/services/edflex/edflex.component').then((cmp) => cmp.EdflexComponent),
    },

    {
      path: EDFLEX_ROUTING.tabSlug,
      title: serviceTabTitleResolver,
      showInSiteMap: false,
      resolve: {
        content: TabContentFromSlugResolver,
      },
      loadComponent: async () =>
        (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
          .ServiceWithRichContentComponent,
    },

    {
      path: '',
      showInSiteMap: false,
      redirectTo: EDFLEX_ROUTING.formations,
      pathMatch: 'full',
    },
  ],
};
