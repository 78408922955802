import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ObendyRoute } from '@core/app-routing';
import { ConnectedRoutesGuards } from '@core/guards/connected-routes.guard';
import { CommunicationsService } from '@features/communications/services/communications.service';
import { FEATURES_ROUTING } from '@features/features.routing';

const COMMUNICATION_PAGE = import('@features/communications/communication-page/communication-page.component');
const COMMUNICATIONS_LIST = import('@features/communications/communication-list/communication-list.component');

function canActivateCommunicationList({ queryParamMap }: ActivatedRouteSnapshot, { url }: RouterStateSnapshot) {
  const communicationsService = inject(CommunicationsService);
  const router = inject(Router);
  const currentPage = queryParamMap.get('page');
  if (typeof currentPage !== 'string') {
    return router.createUrlTree([url], { queryParams: { page: 1 } });
  }
  communicationsService.setCurrentPage(currentPage);
  return true;
}

export const COMMUNICATIONS_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.communications,
  showInSiteMap: true,
  title: 'Actualités',
  loadComponent: async () => (await COMMUNICATION_PAGE).CommunicationPageComponent,
  loadChildren: () => [
    {
      path: '',
      showInSiteMap: false,
      canActivate: [canActivateCommunicationList],
      loadComponent: async () => (await COMMUNICATIONS_LIST).CommunicationListComponent,
    },
  ],
  canActivate: ConnectedRoutesGuards,
  resolve: {
    highlightedCommunications: () => inject(CommunicationsService).highlightedCommunications$,
  },
};
