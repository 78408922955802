import { ObendyRoute } from '@core/app-routing';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { FEATURES_ROUTING } from '@features/features.routing';
import { ASSESS_FIRST_ROUTING } from '@features/services/assess-first/assess-first.routing';
import { AssessFirstTabRedirectGuard } from '@features/services/assess-first/assessFirstTabRedirect.guard';
import { isAssessFirstActivated } from '@features/services/assess-first/isAssessFirstActived.guard';
import { AuthorizationContextResolver } from '@features/services/external-service/resolvers/authorization-context.resolver';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { SERVICES_ROUTING } from '@features/services/services.routing';

export const ASSESS_FIRST_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.assessFirst,
  showInSiteMap: false,
  title: serviceTabTitleResolver,
  canActivate: [updateServiceConfigurationMiddleware],
  data: {
    serviceId: SERVICES_ROUTING.assessFirst,
  },
  resolve: {
    authorizationContext: AuthorizationContextResolver([
      '/',
      FEATURES_ROUTING.services,
      SERVICES_ROUTING.assessFirst,
      ASSESS_FIRST_ROUTING.personalityTest,
    ]),
  },

  loadChildren: () => [
    {
      path: ASSESS_FIRST_ROUTING.personalityTest,
      showInSiteMap: false,
      loadComponent: () =>
        import(
          '@features/services/assess-first/assess-first-personality-test/assess-first-personality-test.component'
        ).then((cmp) => cmp.AssessFirstPersonalityTestComponent),
      canActivate: [isAssessFirstActivated],
    },

    {
      path: ASSESS_FIRST_ROUTING.tabSlug,
      title: serviceTabTitleResolver,
      canActivate: [AssessFirstTabRedirectGuard],
      showInSiteMap: false,
      resolve: {
        content: TabContentFromSlugResolver,
      },
      loadComponent: async () =>
        (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
          .ServiceWithRichContentComponent,
    },

    {
      path: '',
      showInSiteMap: false,
      canActivate: [AssessFirstTabRedirectGuard],
      loadComponent: async () =>
        (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
          .ServiceWithRichContentComponent,
    },
  ],
};
