import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { selectPartner } from '@stores/partner/partner.selectors';
import { info } from '@wizbii-utils/angular/stores';
import { combineLatest, map, take } from 'rxjs';

export const isLoggedGuard: CanActivateFn = () => {
  const store = inject(Store);
  const signRoutes = inject(Router).createUrlTree(['/', FEATURES_ROUTING.sign]);

  return combineLatest([store.select(info), store.select(selectPartner)]).pipe(
    take(1),
    map(([jwtInfo, partner]) => {
      const jwtExternalServicesPackageId = jwtInfo ? jwtInfo['external-services-package-id'] : null;
      const partnerExternalServicesPackageId = partner?.partnerExternalServicesPackage.externalServicesPackageId;
      const shouldHaveAccess = partnerExternalServicesPackageId === jwtExternalServicesPackageId;

      return !jwtInfo || !shouldHaveAccess ? signRoutes : true;
    })
  );
};
