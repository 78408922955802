import { EventFull } from '@models/wizbii-event-full/wizbii-event-full.model';
import { createReducer, on } from '@ngrx/store';
import { eventFullPutSuccess } from '@stores/event-full/event-full.actions';

export const eventFullFeatureKey = 'eventFull';
export type EventFullState = EventFull | null;

const initialState: EventFullState = null as EventFullState;

export const eventFullReducer = createReducer(
  initialState,
  on(eventFullPutSuccess, (_, { eventFull }) => eventFull)
);
