import { ObendyRoutes } from '@core/app-routing';
import { canAccessPasswordExpired } from '@core/guards/can-access-password-expired.guard';
import { isLoggedGuard } from '@core/guards/is-logged.guard';
import { isNotGuardianGuard } from '@core/guards/is-not-guardian.guard';
import { shouldFinishOnboardingGuard } from '@core/guards/should-finish-onboarding.guard';
import { ssoConnect } from '@core/guards/sso.guard';
import { FEATURES_ROUTING } from '@features/features.routing';
import { MAILING_LIST_ROUTING } from '@features/mailing-list/mailing-list.routing';
import { unsubscribeResolver } from '@features/mailing-list/unsubscribe.resolver';

export const UNCONNECTED_ROUTES: ObendyRoutes = [
  {
    path: FEATURES_ROUTING.loadingSso,
    showInSiteMap: false,
    loadComponent: () =>
      import('@features/sso/loading-sso/loading-sso.component').then((component) => component.LoadingSsoComponent),
    data: {
      showMenu: false,
      showFooter: false,
    },
  },
  {
    path: FEATURES_ROUTING.sso,
    canActivate: [ssoConnect],
    showInSiteMap: false,
    loadComponent: () => import('@features/sso/sso.component').then((component) => component.SsoComponent),
    data: {
      showMenu: false,
      showFooter: false,
    },
  },

  {
    path: FEATURES_ROUTING.expiredPassword,
    title: 'Mot de passe expiré',
    canActivate: [canAccessPasswordExpired],
    showInSiteMap: false,
    loadComponent: () =>
      import('@features/expired-password/expired-password.component').then((cmp) => cmp.ExpiredPasswordComponent),
    data: {
      showMenu: false,
      showFooter: false,
    },
  },

  {
    path: FEATURES_ROUTING.revokedMinor,
    title: 'Mineur révoqué',
    showInSiteMap: false,
    loadComponent: () =>
      import('@features/sign/revoked-minor/revoked-minor.component').then((c) => c.RevokedMinorComponent),
    data: {
      showMenu: false,
      showFooter: true,
    },
  },

  {
    path: FEATURES_ROUTING.reviewLatestCGU,
    title: 'CGU',
    showInSiteMap: false,
    loadComponent: () =>
      import('@features/review-latest-cgu/review-latest-cgu.component').then((cmp) => cmp.ReviewLatestCGUComponent),
    data: {
      showMenu: false,
      showFooter: false,
    },
  },

  {
    path: FEATURES_ROUTING.reviewConfidentiality,
    title: 'Politique de confidentialité',
    showInSiteMap: false,
    loadComponent: () =>
      import('@features/review-confidentiality/review-confidentiality.component').then(
        (cmp) => cmp.ReviewConfidentialityComponent
      ),
    data: {
      showMenu: false,
      showFooter: false,
    },
  },

  {
    path: FEATURES_ROUTING.changePlan,
    title: 'Changement de plan',
    showInSiteMap: false,
    canActivate: [isLoggedGuard, isNotGuardianGuard, shouldFinishOnboardingGuard],
    loadComponent: () => import('@features/change-plan/change-plan.component').then((cmp) => cmp.ChangePlanComponent),
    data: {
      showMenu: false,
      showFooter: false,
    },
  },

  {
    path: FEATURES_ROUTING.acceptNewCGUComponent,
    title: 'Mise à jour de nos CGU',
    showInSiteMap: false,
    loadComponent: () =>
      import('@features/accept-new-cgu/accept-new-cgu.component').then((cmp) => cmp.AcceptNewCGUComponent),
    data: {
      showMenu: false,
      showFooter: false,
    },
  },

  {
    path: FEATURES_ROUTING.mailList,
    title: 'Désinscription',
    showInSiteMap: false,

    loadChildren: () => [
      {
        path: '',
        showInSiteMap: false,
        redirectTo: `${FEATURES_ROUTING.mailList}/${MAILING_LIST_ROUTING.unsubscribeMail}`,
        pathMatch: 'full',
      },

      {
        path: MAILING_LIST_ROUTING.unsubscribeMail,
        showInSiteMap: false,
        loadComponent: () =>
          import('@features/mailing-list/unsubscribe-mail/unsubscribe-mail.component').then(
            (cmp) => cmp.UnsubscribeMailComponent
          ),
        data: {
          showMenu: false,
          showFooter: false,
        },
        resolve: {
          unsubscribeData: unsubscribeResolver,
        },
      },
    ],
  },

  {
    path: FEATURES_ROUTING.siteMap,
    title: 'Plan du site',
    loadComponent: () => import('@features/site-map/site-map.component').then((cmp) => cmp.SiteMapComponent),
    showInSiteMap: false,
  },
];
