import { ObendyRoute } from '@core/app-routing';
import { ConnectedRoutesGuards } from '@core/guards/connected-routes.guard';
import { LegalGuardianMatcher } from '@features/legal-guardian-dashboard/routes/guards/legal-guardian-matcher.guard';
import { childResolver } from '@features/legal-guardian-dashboard/routes/resolvers/child.resolver';
import { dashboardResolver } from '@features/legal-guardian-dashboard/routes/resolvers/dashboard.resolver';
import { minorRegistrationResolver } from '@features/legal-guardian-dashboard/routes/resolvers/minor-registration.resolver';

const LEGAL_GUARDIAN_DASHBOARD = import('@features/legal-guardian-dashboard/legal-guardian-dashboard.component');
const CHILDREN_LIST = import('@features/legal-guardian-dashboard/guardian-children/guardian-children.component');
const REGISTER_CHILD = import('@features/legal-guardian-dashboard/register-child/register-child.component');

export const LEGAL_GUARDIAN_DASHBOARD_ROUTE: ObendyRoute = {
  path: '',
  showInSiteMap: false,
  canActivate: ConnectedRoutesGuards,
  canMatch: [LegalGuardianMatcher],
  resolve: { dashboard: dashboardResolver },
  loadComponent: async () => (await LEGAL_GUARDIAN_DASHBOARD).LegalGuardianDashboardComponent,
  data: {
    showBreadcrumb: false,
  },
  children: [
    {
      path: '',
      showInSiteMap: false,
      loadComponent: async () => (await CHILDREN_LIST).GuardianChildrenComponent,
    },
    {
      path: ':childId',
      showInSiteMap: false,
      loadComponent: async () => (await REGISTER_CHILD).RegisterChildComponent,
      resolve: { child: childResolver, minorRegistration: minorRegistrationResolver },
    },
  ],
};
