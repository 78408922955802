import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { LifeMoment } from '@models/profile-page/life-moments.interface';
import { Store } from '@ngrx/store';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { LifeMomentsWebService } from '@webservices/life-moments.webservice';
import { userId } from '@wizbii-utils/angular/stores';
import { Observable, switchMap } from 'rxjs';

export const LifeMomentsResolver: ResolveFn<Observable<LifeMoment | undefined>> = () => {
  const store = inject(Store);
  const lifeMomentsWebservice = inject(LifeMomentsWebService);

  return selectIfDefined(store, userId).pipe(switchMap((userId) => lifeMomentsWebservice.getLifeMoments(userId)));
};
