import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { BigFiveResult } from '@models/big-five/big-five-result';
import { Store } from '@ngrx/store';
import { loadBigFiveResultsSuccess } from '@stores/big-five/big-five.actions';
import { hasBigFiveResults, selectBigFiveResults } from '@stores/big-five/big-five.selectors';
import { BigFiveWebservice } from '@webservices/big-five/big-five.webservice';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

export const canAccessBigFiveResult: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);
  const bigFiveWebservice = inject(BigFiveWebservice);

  return store.select(hasBigFiveResults).pipe(
    switchMap((hasResults) => {
      if (hasResults) return store.select(selectBigFiveResults);

      return bigFiveWebservice.getResult();
    }),
    filter((results): results is BigFiveResult[] => results !== null),
    tap((results) => store.dispatch(loadBigFiveResultsSuccess({ results }))),
    map((result) => {
      return result.length > 0 ? true : router.parseUrl(`/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.bigFive}`);
    }),
    catchError(() => of(false))
  );
};
