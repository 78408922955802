import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { isLegalGuardian } from '@stores/profile/profile.selectors';
import { filter, map } from 'rxjs';

export const isLegalGuardianMatcher: CanMatchFn = () => {
  return inject(Store)
    .select(isLegalGuardian)
    .pipe(
      filter((isLegalGuardian): isLegalGuardian is boolean => typeof isLegalGuardian === 'boolean'),
      map((isLegalGuardian) => isLegalGuardian)
    );
};
