import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MONEY_DASHBOARD_ROUTING } from '@domains/money/money-dashboard/money-dashboard.routing';
import { FEATURES_ROUTING } from '@features/features.routing';
import { MONEY_ROUTING } from '@features/services/money/money.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { userId } from '@wizbii-utils/angular/stores';
import { FibiiWebservice } from '@wizbii-utils/angular/webservices';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const loadNextDossierToUpdateGuard: CanActivateFn = () => {
  const router = inject(Router);
  const fibiiWebservice = inject(FibiiWebservice);
  const store = inject(Store);

  return selectOnceIfDefined(store, userId).pipe(
    switchMap((id) => fibiiWebservice.getNextSuggestionToUpdate(id)),
    map(({ suggestionId }) =>
      router.parseUrl(
        `/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.money}/${MONEY_DASHBOARD_ROUTING.simulation}/${MONEY_ROUTING.dossierUpdate}/${suggestionId}`
      )
    ),
    catchError(() => of(router.parseUrl(`/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.money}`)))
  );
};
