import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { partnerId } from '@stores/partner/partner.selectors';
import { DashboardWebservice } from '@webservices/dashboard/dashboard.webservice';
import { filter, switchMap } from 'rxjs';

export const dashboardResolver = () => {
  const dashboardWebservice = inject(DashboardWebservice);
  return inject(Store)
    .select(partnerId)
    .pipe(
      filter((partnerId): partnerId is string => !!partnerId),
      switchMap((partnerId) => dashboardWebservice.getDashboardPage(partnerId))
    );
};
