import { ObendyRoute } from '@core/app-routing';
import { isPasswordExpiredGuard } from '@core/guards/is-password-expired.guard';
import { BANKING_PRODUCTS_ROUTE } from '@core/routes/connected-routes/banking-products.routes';
import { COMMUNICATIONS_ROUTE } from '@core/routes/connected-routes/communications.routes';
import { CONTACT_ROUTE } from '@core/routes/connected-routes/contact.routes';
import { DASHBOARD_ROUTE } from '@core/routes/connected-routes/dashboard.routes';
import { FAQ_ROUTE } from '@core/routes/connected-routes/faq.routes';
import { GUARDIAN_ROUTE } from '@core/routes/connected-routes/guardian.routes';
import { LEGAL_ROUTE } from '@core/routes/connected-routes/legal.routes';
import { LOGIN_ROUTES } from '@core/routes/connected-routes/login.routes';
import { PROFILE_ROUTE } from '@core/routes/connected-routes/profile.routes';
import { REDIRECTION_ROUTE } from '@core/routes/connected-routes/redirection.routes';
import { SERVICES_ROUTE } from '@core/routes/connected-routes/services.routes';
import { UPSELL_ROUTE } from '@core/routes/connected-routes/upsell.routes';
import { FEATURES_ROUTING } from '@features/features.routing';

export const CONNECTED_ROUTE: ObendyRoute = {
  path: '',
  canActivate: [isPasswordExpiredGuard],
  runGuardsAndResolvers: 'always',
  showInSiteMap: false,

  loadChildren: () => [
    DASHBOARD_ROUTE,
    PROFILE_ROUTE,
    BANKING_PRODUCTS_ROUTE,
    UPSELL_ROUTE,
    FAQ_ROUTE,
    LEGAL_ROUTE,
    CONTACT_ROUTE,
    SERVICES_ROUTE,
    ...LOGIN_ROUTES,
    GUARDIAN_ROUTE,
    REDIRECTION_ROUTE,
    COMMUNICATIONS_ROUTE,

    {
      path: '',
      showInSiteMap: false,
      redirectTo: FEATURES_ROUTING.sign,
      pathMatch: 'full',
    },
  ],
};
