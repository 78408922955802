import { ResolveFn } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';

export const serviceIdResolver: ResolveFn<string> = (route) => {
  const serviceId = route.paramMap.get(SERVICE_ID_ROUTE_PARAM);

  if (!serviceId) throw new Error('Could not get serviceId from route');

  return serviceId;
};
