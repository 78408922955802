import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { ONBOARDING_ROUTING } from '@features/onboarding/routes';
import { Store } from '@ngrx/store';
import { hasCompletedOnboarding } from '@stores/profile/profile.selectors';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { catchError, iif, of, switchMap } from 'rxjs';

export const shouldFinishOnboardingGuard: CanActivateFn = () => {
  const store = inject(Store);
  const onboardingPreferencesRoute = inject(Router).createUrlTree([
    '/',
    FEATURES_ROUTING.onboarding,
    ONBOARDING_ROUTING.preferences,
  ]);

  return selectOnceIfDefined(store, hasCompletedOnboarding).pipe(
    switchMap((completedOnboarding) => iif(() => completedOnboarding, of(true), of(onboardingPreferencesRoute))),
    catchError(() => of(onboardingPreferencesRoute))
  );
};
