import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { capitalize } from '@global/utils/utils';
import { Store } from '@ngrx/store';
import { partnerWordingVariations } from '@stores/partner/partner.selectors';
import { map } from 'rxjs';

export const serviceDesignationResolver: ResolveFn<string> = () =>
  inject(Store)
    .select(partnerWordingVariations)
    .pipe(map((wordings) => capitalize(wordings.serviceDesignationPlural)));
