import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { SIGN_ROUTING } from '@features/sign/sign.routing';
import { MiddlewareMatchFn } from '@models/core/middleware';
import { Store } from '@ngrx/store';
import { AuthenticationV3WebService } from '@webservices/authentication/authentication.web-service';
import { JwtTokens } from '@wizbii-utils/angular/jwt';
import { isLogged, jwtDelete, jwtSet } from '@wizbii-utils/angular/stores';
import { Observable, catchError, iif, map, of, switchMap, tap } from 'rxjs';

export type Status = 'userDeleted' | 'userNotFound' | 'userNotAuthorized';

export const LOGIN_TOKEN_KEY = 'login-token';
export const SSO_STATE_KEY = 'state';
export const JWT_TOKEN = 'jwt-token';
export const JWT_REFRESH_TOKEN = 'jwt-refresh-token';

const errorRoutes: Record<Status, string> = {
  userNotFound: '/',
  userDeleted: `/${FEATURES_ROUTING.emailSent}`,
  userNotAuthorized: `/${FEATURES_ROUTING.sign}/${SIGN_ROUTING.expiredToken}`,
};

export const loginWithLoginToken = (
  authWebservice: AuthenticationV3WebService,
  store: Store,
  router: Router,
  loginToken: string | null,
  jwtToken: string | null,
  jwtRefreshToken: string | null,
  ssoState: string | null
): Observable<boolean | UrlTree> => {
  if (!loginToken && !jwtToken && !jwtRefreshToken && !ssoState) {
    return of(true);
  }

  if (loginToken) {
    return authenticateWithLoginToken(store, router, authWebservice, loginToken, ssoState);
  }

  if (jwtToken && jwtRefreshToken) {
    return authenticateWithJwtToken(
      store,
      router,
      authWebservice,
      { token: jwtToken, refreshToken: jwtRefreshToken },
      jwtToken
    );
  }

  return of(true);
};

export const loginTokenMiddleware: MiddlewareMatchFn = (): Observable<true> => {
  const store = inject(Store);
  const authWebservice = inject(AuthenticationV3WebService);
  const router = inject(Router);

  const currentUrl = new URL(window.location.href);

  return loginWithLoginToken(
    authWebservice,
    store,
    router,
    currentUrl.searchParams.get(LOGIN_TOKEN_KEY),
    currentUrl.searchParams.get(JWT_TOKEN),
    currentUrl.searchParams.get(JWT_REFRESH_TOKEN),
    currentUrl.searchParams.get(SSO_STATE_KEY)
  ).pipe(map(() => true));
};

const handleError = (
  store: Store,
  router: Router,
  { status, token }: { status: Status; token?: string | null }
): Observable<UrlTree> => {
  if (status === 'userDeleted') {
    return of(router.parseUrl(`${FEATURES_ROUTING.sign}#${status}`));
  }
  store.dispatch(jwtDelete());
  const route = typeof token === 'string' ? `${errorRoutes[status]}?token=${token}` : errorRoutes[status];
  return of(router.parseUrl(route));
};

const renewJwtStateToken = (store: Store, jwtTokens: JwtTokens): void => {
  store.dispatch(jwtSet({ jwtTokens }));
};

const setJwtStateWithJwtToken = (
  store: Store,
  router: Router,
  authWebservice: AuthenticationV3WebService,
  jwtTokensFromParams: JwtTokens,
  jwtToken: string | null
): Observable<boolean | UrlTree> =>
  authWebservice.fromRefreshToken(jwtTokensFromParams).pipe(
    tap((jwtTokens) => renewJwtStateToken(store, jwtTokens)),
    switchMap(() => of(true)),
    catchError(() => handleError(store, router, { status: 'userNotAuthorized', token: jwtToken }))
  );

const authenticateWithJwtToken = (
  store: Store,
  router: Router,
  authWebservice: AuthenticationV3WebService,
  jwtTokensFromParams: JwtTokens,
  jwtToken: string | null
): Observable<boolean | UrlTree> =>
  store
    .select(isLogged)
    .pipe(
      switchMap((islogged) =>
        iif(
          () => islogged,
          of(true),
          setJwtStateWithJwtToken(store, router, authWebservice, jwtTokensFromParams, jwtToken)
        )
      )
    );

/*
    README:  In marketing emails, some routes are directly linked with a login-token.
    If the user is already connected, we will ignore that token.
    If not we will use the token.
    Also, if the user click multiple times on links with the same login-token,
    it will not be an issue.
   */
const authenticateWithLoginToken = (
  store: Store,
  router: Router,
  authWebservice: AuthenticationV3WebService,
  loginToken: string,
  ssoState: string | null
): Observable<boolean | UrlTree> => setJwtStateWithLoginToken(store, router, authWebservice, loginToken, ssoState);

const setJwtStateWithLoginToken = (
  store: Store,
  router: Router,
  authWebservice: AuthenticationV3WebService,
  loginToken: string,
  ssoState: string | null
): Observable<boolean | UrlTree> =>
  authWebservice.loginWithToken(loginToken, ssoState).pipe(
    tap((jwtTokens) => renewJwtStateToken(store, jwtTokens)),
    map(() => true),
    catchError(({ status }) =>
      store.select(isLogged).pipe(
        switchMap((isLogged) => {
          if ((status === 404 || status === 403) && isLogged) {
            return of(true);
          }
          if (status === 401) {
            return handleError(store, router, { status: 'userDeleted' });
          }
          if (status === 404 || status === 403) {
            return handleError(store, router, { status: 'userNotAuthorized' });
          }
          return handleError(store, router, { status: 'userNotFound' });
        })
      )
    )
  );
