import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { ServiceTab, ServiceTabContentTypeEnum } from '@models/services/service';
import { Store } from '@ngrx/store';
import { service } from '@stores/services/services.selector';
import { isLogged } from '@wizbii-utils/angular/stores';
import { combineLatest, filter, map } from 'rxjs';

const hasGenericListTabs = (tabs: ServiceTab[]): boolean => {
  return tabs
    .map(({ contentType }) => contentType)
    .some((contentType) => contentType === ServiceTabContentTypeEnum.GenericList);
};

export const ServiceWithList: CanMatchFn = (_route, segments) => {
  const serviceId = segments[0].path;
  const store = inject(Store);

  return combineLatest([store.select(service(serviceId)), store.select(isLogged)]).pipe(
    filter(([service, isLogged]) => (isLogged && Boolean(service)) || !isLogged),
    map(([service, _]) => !service || hasGenericListTabs(service.tabs))
  );
};
