import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Service } from '@models/services/service';
import { Store } from '@ngrx/store';
import { service } from '@stores/services/services.selector';
import { selectIfDefined } from '@stores/utils/selects.operators';

export const blogServiceResolver: ResolveFn<Service> = (route) => {
  const store = inject(Store);
  return selectIfDefined(store, service(route.data.blogServiceId));
};
