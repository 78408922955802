import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { hasPaymentSucceeded } from '@stores/purchase';
import { Observable, iif, of, switchMap } from 'rxjs';

export function isPaymentSuccessGuard(): Observable<boolean | UrlTree> {
  const dashboard = inject(Router).parseUrl(FEATURES_ROUTING.dashboard);

  return inject(Store)
    .select(hasPaymentSucceeded)
    .pipe(switchMap((hasPaymentSucceeded) => iif(() => hasPaymentSucceeded, of(true), of(dashboard))));
}
