import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { partnerId } from '@stores/partner/partner.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { StrapiService } from '@wizbii-utils/angular/webservices';
import {
  ArticleFlat,
  BlogTypeEnum,
  HelpsArticleFlat,
  LangEnum,
  deserializeArticleCollection,
  deserializeHelpsArticleCollection,
} from '@wizbii/utils/models';
import { Observable, map, switchMap } from 'rxjs';

export const strapiBlogArticleResolver: (strapiUrl: string) => ResolveFn<Observable<ArticleFlat<BlogTypeEnum>>> =
  (strapiUrl: string) => (route: ActivatedRouteSnapshot) => {
    const store = inject(Store);
    const strapiService = inject(StrapiService);

    return selectIfDefined(store, partnerId).pipe(
      switchMap((obendyPartnerId) =>
        strapiService.getArticles(
          LangEnum.fr,
          BlogTypeEnum.Articles,
          strapiUrl,
          {
            sortBy: { publishedAt: 'desc' },
            slugs: [route.params.articleSlug],
            pagination: { page: 1, pageSize: 9 },
            deepness: 3,
          },
          obendyPartnerId
        )
      ),
      map(deserializeArticleCollection),
      map(({ articles }) => articles[0])
    );
  };

export const strapiBlogAdvicesResolver: (strapiUrl: string) => ResolveFn<Observable<ArticleFlat<BlogTypeEnum>>> =
  (strapiUrl: string) => (route: ActivatedRouteSnapshot) => {
    const store = inject(Store);
    const strapiService = inject(StrapiService);

    return selectIfDefined(store, partnerId).pipe(
      switchMap(() =>
        strapiService.getArticles(LangEnum.fr, BlogTypeEnum.Advices, strapiUrl, {
          sortBy: { publishedAt: 'desc' },
          slugs: [route.params.articleSlug],
          pagination: { page: 1, pageSize: 9 },
          deepness: 3,
        })
      ),
      map(deserializeArticleCollection),
      map(({ articles }) => articles[0])
    );
  };

export const strapiBlogHelpResolver: (strapiUrl: string) => ResolveFn<Observable<HelpsArticleFlat>> =
  (strapiUrl: string) => (route: ActivatedRouteSnapshot) => {
    const store = inject(Store);
    const strapiService = inject(StrapiService);

    return selectIfDefined(store, partnerId).pipe(
      switchMap(() =>
        strapiService.getArticles(LangEnum.fr, BlogTypeEnum.Helps, strapiUrl, {
          sortBy: { publishedAt: 'desc' },
          slugs: [route.params.articleSlug],
          pagination: { page: 1, pageSize: 9 },
          deepness: 3,
        })
      ),
      map(deserializeHelpsArticleCollection),
      map(({ articles }) => articles[0])
    );
  };
