import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { setInitialState } from '@stores/onboarding/onboarding.actions';
import { customerId } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { OnboardingWebservice } from '@webservices/onboarding/onboarding.webservice';
import { catchError, map, of, switchMap } from 'rxjs';

export const arePreferencesNotSet: CanActivateFn = () => {
  const onboardingWebservice = inject(OnboardingWebservice);
  const store = inject(Store);

  const dashboardRoute = inject(Router).createUrlTree(['/', FEATURES_ROUTING.dashboard]);

  return selectIfDefined(store, customerId).pipe(
    switchMap((id) => onboardingWebservice.get(id)),
    map((onboarding) => {
      if (onboarding.onboardingCompleted) {
        return dashboardRoute;
      }
      store.dispatch(setInitialState({ onboarding }));
      return true;
    }),
    catchError(() => of(dashboardRoute))
  );
};
