import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { Profile } from '@loyalty-v3/libs';
import { PlanRenewalBanner } from '@models/plan-renewal/plan-renewal-banner';
import { Store } from '@ngrx/store';
import { selectProfile } from '@stores/profile/profile.selectors';
import { ProfileV3WebService } from '@webservices/profile/profile.webservice';
import { filter, switchMap } from 'rxjs';

export const PlanRenewBannerMessageResolver: ResolveFn<PlanRenewalBanner | null> = () => {
  const profileWebservice = inject(ProfileV3WebService);
  const store = inject(Store);

  return store.select(selectProfile).pipe(
    filter((profile): profile is Profile => !!profile),
    switchMap((profile) => profileWebservice.getPlanRenewBannerMessage(profile.id))
  );
};
