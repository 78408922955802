import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { Store } from '@ngrx/store';
import { currentPlanGroupId } from '@stores/profile/profile.selectors';
import { service as serviceSelector } from '@stores/services/services.selector';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { ServicesV3WebService } from '@webservices/services/services.webservice';
import { combineLatest, map, switchMap } from 'rxjs';

export const OneTabContentResolver = (route: ActivatedRouteSnapshot) => {
  const serviceId = route.params[SERVICE_ID_ROUTE_PARAM];

  const store = inject(Store);
  const servicesV3WebService = inject(ServicesV3WebService);

  const service$ = selectIfDefined(store, serviceSelector(serviceId));
  const planGroupId$ = selectIfDefined(store, currentPlanGroupId);

  return combineLatest([service$, planGroupId$]).pipe(
    switchMap(([service, planGroupId]) => {
      return servicesV3WebService.readServiceSimpleTabContent({
        serviceId: service.id,
        planGroupId: planGroupId,
        tabId: service.tabs[0].id,
      });
    }),
    map((response) => response?.html)
  );
};
