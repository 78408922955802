import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { ServiceTab, ServiceTabContentTypeEnum } from '@models/services/service';
import { Store } from '@ngrx/store';
import { service } from '@stores/services/services.selector';
import { filter, map } from 'rxjs';

export const CurrentTabIsGenericList: CanMatchFn = (_route: Route, segments: UrlSegment[]) => {
  const serviceId = inject(Router).getCurrentNavigation()?.extractedUrl.root.children.primary.segments[1].path;

  if (!serviceId) {
    throw new Error('Service ID not found in URL');
  }

  const slug = segments[0].path;

  const isGenericListTab = (tabs: ServiceTab[]): boolean =>
    tabs.find((tab) => tab.slug === slug)?.contentType === ServiceTabContentTypeEnum.GenericList || false;
  return inject(Store)
    .select(service(serviceId))
    .pipe(
      filter(Boolean),
      map(({ tabs }) => tabs),
      map(isGenericListTab)
    );
};
