import { CurrencyPipe } from '@angular/common';
import { EnvironmentProviders, Provider } from '@angular/core';
import { environment } from '@environment';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as moneyEffects from '@stores/money/money.effects';
import { moneyFeatureKey, moneyReducer } from '@stores/money/money.reducer';
import { MoneyB2bWebservice } from '@webservices/money/money-b2b.webservice';
import { AlgoliaClientService } from '@wizbii-utils/angular/algolia';
import {
  CONTACT_API_CONFIG,
  ContactWebservice,
  ContentAdvicesApiWebservice,
  FIBII_API_CONFIG,
  FILE_API_CONFIG,
  FibiiWebservice,
  FileWebservice,
  SUGGESTIONS_API_CONFIG,
  SuggestionsWebservice,
} from '@wizbii-utils/angular/webservices';

export const WIZBII_MONEY_PROVIDERS: (Provider | EnvironmentProviders)[] = [
  FileWebservice,
  CurrencyPipe,
  MoneyB2bWebservice,
  {
    provide: FILE_API_CONFIG,
    useValue: {
      baseUrl: environment.api.file,
      googleStorageUrl: environment.api.googleStorage,
      wizbiiFilesBucket: environment.wizbiiFiles,
    },
  },
  ContentAdvicesApiWebservice,
  SuggestionsWebservice,
  {
    provide: SUGGESTIONS_API_CONFIG,
    useValue: {
      baseUrl: environment.api.suggestion,
    },
  },
  FibiiWebservice,
  {
    provide: FIBII_API_CONFIG,
    useValue: {
      baseUrl: `${environment.api.fibii}`,
    },
  },
  AlgoliaClientService,
  {
    provide: CONTACT_API_CONFIG,
    useValue: {
      appId: environment.applicationId,
      baseUrl: environment.api.contact,
    },
  },
  ContactWebservice,
  provideState({ name: moneyFeatureKey, reducer: moneyReducer }),
  provideEffects(moneyEffects),
];
