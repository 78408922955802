import { inject } from '@angular/core';
import { type CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { UPSELL_ROUTING } from '@features/upsell/routes/upsell.routing';
import { UpsellConfigService } from '@features/upsell/upsell-config.service';
import { map, take } from 'rxjs';

export const redirectToPurchasePageGuard: CanActivateFn = () => {
  const router = inject(Router);

  return inject(UpsellConfigService).upsellConfig$.pipe(
    take(1),
    map(
      ({ bankingProducts }) =>
        bankingProducts.length > 0 || router.parseUrl(`/${FEATURES_ROUTING.upsell}/${UPSELL_ROUTING.purchasePage}`)
    )
  );
};
