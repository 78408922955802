import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { Profession } from '@models/profession/profession';
import { WizbiiProfessionWebservice } from '@webservices/profession/profession.webservice';
import { catchNotFound } from '@wizbii-utils/angular/core';
import { Observable } from 'rxjs';

export const professionResolver: ResolveFn<Observable<Profession>> = (route) => {
  return inject(WizbiiProfessionWebservice)
    .getProfession(route.paramMap.get('professionId') ?? '')
    .pipe(catchNotFound(inject(Router), ['404']));
};
