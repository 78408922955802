import { ObendyRoutes } from '@core/app-routing';
import { GRAND_MERCREDI_ROUTE } from '@core/routes/connected-routes/services-routes/blogs-routes/grand-mercredi.routes';
import { LIVEMENTOR_ROUTE } from '@core/routes/connected-routes/services-routes/blogs-routes/livementor.routes';
import { WIZBII_BLOG_ROUTE } from '@core/routes/connected-routes/services-routes/blogs-routes/wizbii-blog.routes';
import { WIZBII_BUDGET_ROUTE } from '@core/routes/connected-routes/services-routes/blogs-routes/wizbii-budget.routes';
import { WIZBII_INSPIRE_ROUTE } from '@core/routes/connected-routes/services-routes/blogs-routes/wizbii-inspire.routes';
import { WIZBII_TIPS_ROUTE } from '@core/routes/connected-routes/services-routes/blogs-routes/wizbii-tips.routes';

export const BLOGS_ROUTES: ObendyRoutes = [
  WIZBII_BLOG_ROUTE,
  WIZBII_BUDGET_ROUTE,
  WIZBII_INSPIRE_ROUTE,
  WIZBII_TIPS_ROUTE,
  LIVEMENTOR_ROUTE,
  GRAND_MERCREDI_ROUTE,
];
