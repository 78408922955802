import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { EmailPreferenceV2, EmailPreferencesUnsubscribe } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { crmFolderId } from '@stores/partner/partner.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { EmailPreferenceV3WebService } from '@webservices/email-preference/email-preference.webservice';
import { SubscriptionWebService } from '@webservices/subscription.webservice';
import { isLogged, userId } from '@wizbii-utils/angular/stores';
import { Observable, combineLatest, map, of, switchMap, take } from 'rxjs';

export const unsubscribeResolver: ResolveFn<
  Observable<{ emailPreferences: Partial<EmailPreferenceV2>; contactId: string }> | null
> = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const subscriptionWebService = inject(SubscriptionWebService);
  const emailPreferenceV3WebService = inject(EmailPreferenceV3WebService);

  let contactId = route.queryParamMap.get('id');
  if (!contactId) {
    contactId = '';
  }

  const isLoggedSig = store.selectSignal(isLogged);

  if (isLoggedSig()) {
    return selectIfDefined(store, crmFolderId).pipe(
      switchMap((crmFolderId) => subscriptionWebService.getFoldersFor(crmFolderId)),
      map((folders) => {
        let unsubscribeFolders: Record<string, boolean> = {};
        if (folders) {
          folders.map((folder) => {
            if (folder.id) {
              unsubscribeFolders[folder.id] = false;
            }
          });
        }

        return {
          campaigns: {},
          folders: unsubscribeFolders,
        } as EmailPreferencesUnsubscribe;
      }),
      switchMap((emailPreferencesUnsubscribe) =>
        combineLatest([of(emailPreferencesUnsubscribe), store.select(userId)])
      ),
      switchMap(([emailPreferencesUnsubscribe, userId]) =>
        emailPreferenceV3WebService.patch(userId!, emailPreferencesUnsubscribe)
      ),
      map((emailPreferences) => {
        return {
          emailPreferences,
          contactId,
        };
      }),
      take(1)
    );
  }

  const unsubId = route.queryParamMap.get('unsubId');
  const unsubType = route.queryParamMap.get('unsubType');
  if (!unsubId || !unsubType || !contactId) {
    return null;
  }

  const decodedUnsubId = atob(unsubId);
  let decodedUnsubType = atob(unsubType);
  decodedUnsubType = decodedUnsubType.includes('campaign') ? 'campaigns' : 'folders';

  const payload = { campaigns: {}, folders: {} };
  const emailPreferences: EmailPreferenceV2 = {
    ...payload,
    [decodedUnsubType]: { [decodedUnsubId]: false },
  };

  const folderIds: string[] = [];
  if (decodedUnsubType === 'folders') {
    folderIds.push(decodedUnsubId);
  }

  return emailPreferenceV3WebService.saveUnsubscribeReasons(contactId, ['other'], folderIds, null).pipe(
    map(() => ({
      emailPreferences,
      contactId,
    }))
  );
};
