import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { Store } from '@ngrx/store';
import { currentPlanGroupId } from '@stores/profile/profile.selectors';
import { service as serviceSelector } from '@stores/services/services.selector';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { ServicesV3WebService } from '@webservices/services/services.webservice';
import { combineLatest, map, switchMap } from 'rxjs';

export const TabContentFromSlugResolver = (route: ActivatedRouteSnapshot) => {
  const serviceId = route.params[SERVICE_ID_ROUTE_PARAM] ?? route.data.serviceId;
  const tabSlug = route.params.tabSlug;

  const store = inject(Store);
  const servicesV3WebService = inject(ServicesV3WebService);

  const service$ = selectIfDefined(store, serviceSelector(serviceId));
  const planGroupId$ = selectIfDefined(store, currentPlanGroupId);

  return combineLatest([service$, planGroupId$]).pipe(
    switchMap(([service, planGroupId]) => {
      const tabId = service.tabs.find((tab) => tab.slug === tabSlug)?.id;

      if (tabId === undefined) {
        throw new Error('Tab ID must be defined to get tab content');
      }

      return servicesV3WebService.readServiceSimpleTabContent({
        serviceId: service.id,
        planGroupId: planGroupId,
        tabId,
      });
    }),
    map((response) => response?.html)
  );
};
