import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { FAQBlock } from '@loyalty-v3/libs';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FAQWebservice {
  readonly #http = inject(HttpClient);
  readonly #url = environment.api.obendy;

  getFaq(partnerId: string, page: string): Observable<FAQBlock> {
    return this.#http.get<FAQBlock>(`${this.#url}/v2/partner/${partnerId}/page/${page}`);
  }
}
