import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ObendyRoute } from '@core/app-routing';
import { requestServiceAuthorizationToRedirectGuard } from '@core/guards/request-authorization-to-redirect.guard';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { JOBS_ROUTING } from '@features/services/jobs/jobs.routing';
import { jobViewSimilarJobsResolver } from '@features/services/jobs/resolvers/job-view-similar-jobs/job-view-similar-jobs.resolver';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';
import { WizbiiJobsWebservice } from '@webservices/jobs/wizbii-jobs.webservice';

export const WIZBII_JOBS_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.jobs,
  title: serviceTitleResolver,
  showInSiteMap: false,
  canActivate: [updateServiceConfigurationMiddleware],
  data: {
    serviceId: INTERNAL_SERVICES_IDS.job,
  },

  loadChildren: () => [
    {
      path: '',
      redirectTo: JOBS_ROUTING.offers,
      pathMatch: 'full',
      showInSiteMap: false,
    },

    {
      path: JOBS_ROUTING.offers,
      showInSiteMap: false,
      loadComponent: () => import('@features/services/jobs/jobs/jobs.component').then((cmp) => cmp.JobsComponent),
    },

    {
      path: `${JOBS_ROUTING.offers}/${JOBS_ROUTING.jobId}`,
      showInSiteMap: false,
      canActivate: [requestServiceAuthorizationToRedirectGuard()],
      loadComponent: () =>
        import('@features/services/jobs/job-view/job-view.component').then((cmp) => cmp.JobViewComponent),
      resolve: {
        job: (route: ActivatedRouteSnapshot) => inject(WizbiiJobsWebservice).getJob(route.params.jobId),
        similarJobs: jobViewSimilarJobsResolver,
      },
      data: {
        titlePath: 'job.title',
      },
    },

    {
      path: JOBS_ROUTING.tabSlug,
      showInSiteMap: false,
      resolve: {
        content: TabContentFromSlugResolver,
      },
      loadComponent: () =>
        import('@features/services/service-with-rich-content/service-with-rich-content.component').then(
          (cmp) => cmp.ServiceWithRichContentComponent
        ),
    },
  ],
};
