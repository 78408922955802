import { ObendyRoute } from '@core/app-routing';
import { requestServiceAuthorizationToRedirectGuard } from '@core/guards/request-authorization-to-redirect.guard';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { ServiceWithManyTabsActivateGuard } from '@features/services/external-service/guards/service-with-many-tabs.guard';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { missionResolver } from '@features/services/missions/mission-view/resolver/mission.resolver';
import { MISSIONS_ROUTING } from '@features/services/missions/missions/missions.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';

export const ELVIS_ENGAGEMENT_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.elvisEngagement,
  title: serviceTitleResolver,
  showInSiteMap: false,
  data: {
    serviceId: INTERNAL_SERVICES_IDS.elvisEngagement,
  },
  canActivate: [updateServiceConfigurationMiddleware],

  loadChildren: () => [
    {
      path: MISSIONS_ROUTING.missions,
      showInSiteMap: false,
      title: serviceTabTitleResolver,
      loadComponent: () =>
        import('@features/services/missions/missions/missions.component').then((cmp) => cmp.MissionsComponent),
    },

    {
      path: `${MISSIONS_ROUTING.missions}/${MISSIONS_ROUTING.missionSlug}`,
      showInSiteMap: false,
      resolve: { mission: missionResolver },
      data: {
        titlePath: 'mission.title',
      },
      canActivate: [requestServiceAuthorizationToRedirectGuard()],
      loadComponent: () =>
        import('@features/services/missions/mission-view/mission-view.component').then(
          (cmp) => cmp.MissionViewComponent
        ),
    },

    {
      path: MISSIONS_ROUTING.tabSlug,
      title: serviceTabTitleResolver,
      showInSiteMap: false,
      canActivate: [ServiceWithManyTabsActivateGuard],
      resolve: {
        content: TabContentFromSlugResolver,
      },
      loadComponent: async () =>
        (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
          .ServiceWithRichContentComponent,
    },

    {
      path: '',
      redirectTo: MISSIONS_ROUTING.missions,
      pathMatch: 'full',
      showInSiteMap: false,
    },
  ],
};
