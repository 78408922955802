import { inject } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { MiddlewareMatchFn } from '@models/core/middleware';
import { Store } from '@ngrx/store';
import { eventFullPutSuccess } from '@stores/event-full/event-full.actions';
import { EventFullWebservice } from '@webservices/events/events-full.webservice';
import { Observable, map, tap } from 'rxjs';

export const getEventFullMiddlewareMatch: MiddlewareMatchFn = (_, segments: UrlSegment[]): Observable<true> => {
  const eventId = segments.pop()?.path;
  if (!eventId) {
    throw new Error('Could not get eventId in route');
  }

  const store = inject(Store);
  const eventFullWebservice = inject(EventFullWebservice);

  return eventFullWebservice.getEvent(eventId).pipe(
    tap((eventFull) => {
      store.dispatch(eventFullPutSuccess({ eventFull }));
    }),
    map(() => true)
  );
};
