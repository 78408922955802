import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { isMonoplan } from '@stores/plan/plan.selectors';
import { hasTopPlan } from '@stores/selectors/profile-plans.selector';
import { filter, iif, map, of, switchMap } from 'rxjs';

export const ShowCallToActionCardResolver: ResolveFn<boolean> = () => {
  const store = inject(Store);

  return store.select(isMonoplan).pipe(
    filter((isMonoplanP): isMonoplanP is boolean => typeof isMonoplanP === 'boolean'),
    switchMap((isMonoplanP) =>
      iif(() => isMonoplanP, of(false), store.select(hasTopPlan).pipe(map((hasTopPlanP) => !hasTopPlanP)))
    )
  );
};
