import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { ServicesV3WebService } from '@webservices/services/services.webservice';

export const BenefitSubscriptionPageResolver = (route: ActivatedRouteSnapshot) => {
  const serviceId = route.params[SERVICE_ID_ROUTE_PARAM] ?? (route.data.serviceId as string);

  return inject(ServicesV3WebService).getBenefitSubscriptionPage(serviceId);
};

export const BenefitSubscriptionFormResolver = (route: ActivatedRouteSnapshot) => {
  const serviceId = route.params[SERVICE_ID_ROUTE_PARAM] ?? (route.data.serviceId as string);

  return inject(ServicesV3WebService).getBenefitSubscriptionForm(serviceId);
};
