import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { MONEY_DASHBOARD_ROUTING } from '@domains/money/money-dashboard/money-dashboard.routing';
import { FEATURES_ROUTING } from '@features/features.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { dossiers } from '@stores/money/money.selectors';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { DossierStatusEnum } from '@wizbii/utils/models';
import { map } from 'rxjs/operators';

export const canUpdateDossierGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const store = inject(Store);

  return selectOnceIfDefined(store, dossiers).pipe(
    map((dossiers) => dossiers.find((d) => d.suggestionId === route.paramMap.get('suggestionId'))),
    map((dossier) => (dossier ? dossier.status === DossierStatusEnum.ToBeUpdated : false)),
    map((hasDossier) =>
      hasDossier
        ? true
        : router.parseUrl(
            `/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.money}/${MONEY_DASHBOARD_ROUTING.simulation}`
          )
    )
  );
};
