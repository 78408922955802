import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { MONEY_DASHBOARD_ROUTING } from '@domains/money/money-dashboard/money-dashboard.routing';
import { FEATURES_ROUTING } from '@features/features.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { userId } from '@wizbii-utils/angular/stores';
import { QuestionWithResponse, SuggestionsWebservice } from '@wizbii-utils/angular/webservices';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';

export const moneyComplementaryResponsesResolver: ResolveFn<Observable<QuestionWithResponse[]>> = (
  route: ActivatedRouteSnapshot
) => {
  const suggestionsWebservice = inject(SuggestionsWebservice);
  const router = inject(Router);

  return inject(Store)
    .select(userId)
    .pipe(
      filter((userId): userId is string => !!userId),
      take(1),
      switchMap((userId) =>
        suggestionsWebservice.getComplementaryFormResponses(route.params.suggestionId, userId, 'member')
      ),
      map((response) => response?.questionWithResponses ?? []),
      catchError(() => {
        router.navigate(['/', FEATURES_ROUTING.services, SERVICES_ROUTING.money, MONEY_DASHBOARD_ROUTING.simulation]);
        return EMPTY;
      })
    );
};
