import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { Accommodation } from '@models/housing/accomodation';
import { WizbiiHomeWebservice } from '@webservices/home/home.webservice';
import { catchNotFound } from '@wizbii-utils/angular/core';
import { City } from '@wizbii/utils/models';
import { map } from 'rxjs';

export const accommodationResolver: ResolveFn<Accommodation> = (route) => {
  const router = inject(Router);
  const wizbiiHomeWebservice = inject(WizbiiHomeWebservice);

  const mapHousingWithCity = (housing: Accommodation): Accommodation => {
    return {
      ...housing,
      location: new City(housing.location),
    };
  };

  return wizbiiHomeWebservice
    .getAccommodation(route.paramMap.get('accommodationId') ?? '', route.data['partnerId'])
    .pipe(
      map((housing) => mapHousingWithCity(housing)),
      catchNotFound(router, ['404'])
    );
};
