import { inject } from '@angular/core';
import { BasketItem } from '@models/purchase/basket-item';
import { FIRST_PURCHASE, RENEW } from '@models/purchase/basket-item-context';
import { Store } from '@ngrx/store';
import { nextPlan } from '@stores/plan/plan.selectors';
import { currentPlanExpirationDate, fullName, selectProfile } from '@stores/profile/profile.selectors';
import { hasArticleInBasket as hasArticleInBasketSelector } from '@stores/purchase';
import { currentPlan, hasTopPlan } from '@stores/selectors/profile-plans.selector';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { Observable, combineLatest, iif, map, switchMap } from 'rxjs';

export const adultArticleToPurchaseResolver = () => {
  const store = inject(Store);

  const hasArticleInBasket = store.selectSignal(hasArticleInBasketSelector);

  if (hasArticleInBasket()) return null;

  const customerFullName$ = selectIfDefined(store, fullName);
  const currentPlanExpirationDate$ = store.select(currentPlanExpirationDate);
  const currentPlan$ = selectIfDefined(store, currentPlan);
  const nextPlan$ = selectIfDefined(store, selectProfile).pipe(
    switchMap(({ planGroupId, currentPlan }) => store.select(nextPlan(planGroupId, currentPlan.planId)))
  );
  const hasTopPlan$ = selectIfDefined(store, hasTopPlan);

  const planToPurchase$ = hasTopPlan$.pipe(switchMap((hasTopPlan) => iif(() => hasTopPlan, currentPlan$, nextPlan$)));

  const adultArticle$: Observable<BasketItem | null> = combineLatest([
    planToPurchase$,
    customerFullName$,
    currentPlanExpirationDate$,
  ]).pipe(
    map(([planToPurchase, customerFullName, currentPlanExpirationDate]) => {
      if (planToPurchase === undefined || planToPurchase === null) return null;
      const purchasableArticleId = planToPurchase.id;
      const context = currentPlanExpirationDate === null ? FIRST_PURCHASE : RENEW;
      return {
        purchasableArticleId,
        customerId: null,
        customerFullName,
        context,
      };
    })
  );

  return adultArticle$;
};
