import { ObendyRoute } from '@core/app-routing';
import { requestServiceAuthorizationToRedirectGuard } from '@core/guards/request-authorization-to-redirect.guard';
import { canEditDossierGuard } from '@core/guards/wizbii-money/can-edit-dossier.guard';
import { canUpdateDossierGuard } from '@core/guards/wizbii-money/can-update-dossier.guard';
import { canViewDossierGuard } from '@core/guards/wizbii-money/can-view-dossier.guard';
import { dossierCompletionGuard } from '@core/guards/wizbii-money/dossier-completion.guard';
import { dossierValidationGuard } from '@core/guards/wizbii-money/dossier-validation.guard';
import { loadNextDossierToUpdateGuard } from '@core/guards/wizbii-money/load-next-dossier-to-update.guard';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { WIZBII_MONEY_PROVIDERS } from '@core/routes/connected-routes/services-routes/wizbii-money.providers';
import { MoneyRoutingTitlesEnum } from '@core/routes/connected-routes/services-routes/wizbii-money.routes';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { MONEY_PLAYER_CONFIG } from '@core/utils/money-player.config';
import { COMPLEMENTARY_PLAYER_ROUTING } from '@domains/money/complementary-player/complementary-player.routing';
import { ELIGIBILITY_PLAYER_ROUTING } from '@domains/money/eligibility-player/eligibility-player.routing';
import { MONEY_DASHBOARD_ROUTING } from '@domains/money/money-dashboard/money-dashboard.routing';
import { environment } from '@environment';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { MONEY_ROUTING } from '@features/services/money/money.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';
import { moneyComplementaryResponsesResolver } from '@webservices/money/money-complementary-responses.resolver';

export const ELVIS_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.elvis,
  showInSiteMap: false,
  title: serviceTitleResolver,
  canActivate: [updateServiceConfigurationMiddleware],
  data: {
    serviceId: INTERNAL_SERVICES_IDS.elvis,
    strapiUrl: environment.strapi.moneyApi,
    algoliaIndex: environment.algolia.index.wizbiiMoneyHelps,
  },
  providers: [
    {
      provide: MONEY_PLAYER_CONFIG,
      useValue: {
        title: '5 min pour découvrir vos aides',
        image: `${environment.wizbiiImages}/services/illustrations/elvis-money-white-logo.svg`,
      },
    },
  ],

  loadChildren: () => [
    {
      path: '',
      showInSiteMap: false,
      providers: WIZBII_MONEY_PROVIDERS,

      children: [
        // WITH LAYOUT
        {
          path: '',
          showInSiteMap: false,
          loadComponent: () =>
            import('@domains/money/money-dashboard/money-layout/money-layout.component').then(
              (cmp) => cmp.MoneyLayoutComponent
            ),

          loadChildren: () => [
            // SIMULATION HOMEPAGE
            {
              path: MONEY_DASHBOARD_ROUTING.simulation,
              showInSiteMap: false,
              title: MoneyRoutingTitlesEnum.Simulation,
              loadComponent: () =>
                import('@domains/money/money-dashboard/money-dashboard/money-dashboard.component').then(
                  (cmp) => cmp.MoneyDashboardComponent
                ),
            },

            // MY INFOS
            {
              path: MONEY_DASHBOARD_ROUTING.myInfos,
              showInSiteMap: false,
              title: MoneyRoutingTitlesEnum.MyInfos,
              loadComponent: () =>
                import('@domains/money/money-dashboard/my-infos/my-infos.component').then(
                  (cmp) => cmp.MyInfosComponent
                ),
            },

            {
              path: '',
              showInSiteMap: false,
              pathMatch: 'full',
              redirectTo: MONEY_DASHBOARD_ROUTING.helps,
            },
          ],
        },

        // WITHOUT LAYOUT
        {
          path: '',
          showInSiteMap: false,

          loadChildren: () => [
            // SIMULATION
            {
              path: MONEY_DASHBOARD_ROUTING.simulation,
              title: MoneyRoutingTitlesEnum.Simulation,
              showInSiteMap: false,
              canActivate: [requestServiceAuthorizationToRedirectGuard()],

              loadChildren: () => [
                // ELIGIBILITY PLAYER
                {
                  path: MONEY_ROUTING.eligibilityPlayer,
                  title: MoneyRoutingTitlesEnum.Eligibility,
                  showInSiteMap: false,
                  data: { showFooter: false },

                  loadChildren: () => [
                    {
                      path: '',
                      showInSiteMap: false,
                      loadComponent: () =>
                        import(
                          '@domains/money/eligibility-player/eligibility-player/eligibility-player.component'
                        ).then((cmp) => cmp.EligibilityPlayerComponent),
                    },

                    {
                      path: ELIGIBILITY_PLAYER_ROUTING.ending,
                      showInSiteMap: false,
                      loadComponent: () =>
                        import(
                          '@domains/money/eligibility-player/eligibility-ending/eligibility-ending.component'
                        ).then((cmp) => cmp.EligibilityEndingComponent),
                    },

                    {
                      path: `:${ELIGIBILITY_PLAYER_ROUTING.playerQuestion}`,
                      showInSiteMap: false,
                      loadComponent: () =>
                        import(
                          '@domains/money/eligibility-player/eligibility-player/eligibility-player.component'
                        ).then((cmp) => cmp.EligibilityPlayerComponent),
                    },
                  ],
                },

                // COMPLEMENTARY PLAYER
                {
                  path: MONEY_ROUTING.complementaryPlayer,
                  showInSiteMap: false,

                  loadChildren: () => [
                    {
                      path: ':suggestionId',
                      showInSiteMap: false,
                      data: { showFooter: false },

                      loadChildren: () => [
                        {
                          path: `${COMPLEMENTARY_PLAYER_ROUTING.player}/:questionId`,
                          showInSiteMap: false,
                          loadComponent: () =>
                            import(
                              '@domains/money/complementary-player/complementary-player/complementary-player.component'
                            ).then((cmp) => cmp.ComplementaryPlayerComponent),
                          canActivate: [canViewDossierGuard, canEditDossierGuard, dossierCompletionGuard],
                        },

                        {
                          path: COMPLEMENTARY_PLAYER_ROUTING.player,
                          showInSiteMap: false,
                          loadComponent: () =>
                            import(
                              '@domains/money/complementary-player/complementary-player/complementary-player.component'
                            ).then((cmp) => cmp.ComplementaryPlayerComponent),
                          canActivate: [canViewDossierGuard, canEditDossierGuard, dossierCompletionGuard],
                        },

                        {
                          path: COMPLEMENTARY_PLAYER_ROUTING.validation,
                          showInSiteMap: false,
                          loadComponent: () =>
                            import(
                              '@domains/money/complementary-player/complementary-validation/complementary-validation.component'
                            ).then((cmp) => cmp.ComplementaryValidationComponent),
                          resolve: {
                            responses: moneyComplementaryResponsesResolver,
                          },
                          canActivate: [canViewDossierGuard, dossierValidationGuard],
                        },

                        {
                          path: '',
                          showInSiteMap: false,
                          redirectTo: COMPLEMENTARY_PLAYER_ROUTING.player,
                          pathMatch: 'full',
                        },
                      ],
                    },
                  ],
                },

                // DOSSIER UPDATE
                {
                  path: MONEY_ROUTING.dossierUpdate,
                  canActivate: [loadNextDossierToUpdateGuard],
                  showInSiteMap: false,

                  loadChildren: () => [
                    {
                      path: ':suggestionId/:questionId',
                      showInSiteMap: false,
                      loadComponent: () =>
                        import(
                          '@domains/money/dossier-update/dossier-update-player/dossier-update-player.component'
                        ).then((cmp) => cmp.DossierUpdatePlayerComponent),
                      data: { showFooter: false },
                      canActivate: [canUpdateDossierGuard],
                    },

                    {
                      path: ':suggestionId',
                      showInSiteMap: false,
                      loadComponent: () =>
                        import('@domains/money/dossier-update/dossier-update.component').then(
                          (cmp) => cmp.DossierUpdateComponent
                        ),
                      canActivate: [canUpdateDossierGuard],
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          path: MONEY_DASHBOARD_ROUTING.tabSlug,
          title: serviceTabTitleResolver,
          showInSiteMap: false,
          resolve: {
            content: TabContentFromSlugResolver,
          },
          loadComponent: async () =>
            (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
              .ServiceWithRichContentComponent,
        },
      ],
    },
  ],
};
