import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { LegalStatusEnum } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { selectProfile } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { filter, map, take } from 'rxjs';

export const isGuardianGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return selectIfDefined(store, selectProfile).pipe(
    filter((profile) => Boolean(profile.legalStatus) || profile.legalStatus === null),
    take(1),
    map((profile) =>
      profile.legalStatus === LegalStatusEnum.ADULT_LEGAL_GUARDIAN
        ? true
        : router.parseUrl(`/${FEATURES_ROUTING.profile}`)
    )
  );
};
