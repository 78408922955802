import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { MinorRegistration } from '@models/partner/onboarding/onboarding.interface';
import { Store } from '@ngrx/store';
import { partnerId } from '@stores/partner/partner.selectors';
import { MinorRegistrationWebservice } from '@webservices/minor-registration/minor-registration.webservice';
import { switchMap } from 'rxjs';

export const minorRegistrationResolver: ResolveFn<MinorRegistration> = (route: ActivatedRouteSnapshot) => {
  const minorRegistrationWebservice = inject(MinorRegistrationWebservice);

  const childCustomerId = route.params.childId;

  return inject(Store)
    .select(partnerId)
    .pipe(switchMap((id) => minorRegistrationWebservice.getMinorRegistration(id ?? '', childCustomerId ?? '')));
};
