import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { SearchJobItem } from '@loyalty-v3/libs';
import { WizbiiJobsWebservice } from '@webservices/jobs/wizbii-jobs.webservice';
import { Job } from '@wizbii/utils/models';
import { Observable, map } from 'rxjs';

export const jobViewSimilarJobsResolver: ResolveFn<Observable<SearchJobItem[]>> = (route: ActivatedRouteSnapshot) => {
  return inject(WizbiiJobsWebservice)
    .getJobSimilarJobs(route.params.jobId)
    .pipe(map((jobs) => (jobs ?? []).map((job) => fromJobToSearchJobItem(job))));
};

const fromJobToSearchJobItem = (job: Job): SearchJobItem => ({
  _id: job.id,
  company: { ...job.company, logo: job.company.logoUrl },
  domain: {
    id: job.domain,
    source: undefined,
  },
  startDate: new Date(job.startDate).getTime(),
  createdDate: new Date(job.createdDate).getTime(),
  contract: job.contract,
  location: job.location as any,
  duration: job.duration,
  degree: job.degree,
  mission: job.mission,
  locale: job.locale,
  online: job.online,
  profile: job.profile,
  skills: job.skills,
  title: job.title,
  source: job.source,
  externalUrl: job.externalUrl,
  descriptionCompany: job.descriptionCompany,
});
