import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { Service } from '@models/services/service';
import { Store } from '@ngrx/store';
import { service } from '@stores/services/services.selector';
import { filter, map, take } from 'rxjs';

export const serviceTabTitleResolver: ResolveFn<string> = (activatedRoute: ActivatedRouteSnapshot) => {
  const store = inject(Store);

  const serviceId = (activatedRoute.params[SERVICE_ID_ROUTE_PARAM] ?? activatedRoute.data.serviceId) as string;
  const tabSlug = activatedRoute.params.tabSlug as string;

  return store.select(service(serviceId)).pipe(
    filter((service): service is Service => !!service),
    map((service) => service.tabs.find((tab) => tab.slug === tabSlug)?.title ?? service.name),
    take(1)
  );
};
