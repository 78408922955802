import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { BigFiveQuestion } from '@models/big-five/big-five-question';
import { BigFiveWebservice } from '@webservices/big-five/big-five.webservice';
import { tap } from 'rxjs';

export const bigFiveQuestionsResolver: ResolveFn<BigFiveQuestion[] | undefined> = () => {
  const router = inject(Router);
  const bigFiveWebservice = inject(BigFiveWebservice);

  const DEFAULT_QUESTION_SIZE = 120;

  return bigFiveWebservice.getQuestions(DEFAULT_QUESTION_SIZE).pipe(
    tap((questions) => {
      if (!questions || questions.length === 0) {
        router.navigate(['/', FEATURES_ROUTING.services, SERVICES_ROUTING.bigFive], { skipLocationChange: true });
      }
    })
  );
};
