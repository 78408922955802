import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { ApiTraining, Training, TrainingFunding } from '@models/training/training';
import { TrainingWebservice } from '@webservices/trainings/trainings.webservice';
import { catchNotFound } from '@wizbii-utils/angular/core';
import { Observable, map } from 'rxjs';

export const trainingResolver: ResolveFn<Observable<Training>> = (route) => {
  const trainingWebservice = inject(TrainingWebservice);
  const router = inject(Router);

  return trainingWebservice
    .get(route.paramMap.get('trainingId') ?? '')
    .pipe(catchNotFound(router, ['404']), map(mapApiTrainingToTraining));
};

const mapApiTrainingToTraining = (apiTraining: ApiTraining): Training => {
  const {
    id,
    title,
    sessions,
    source,
    contact,
    objectives,
    description,
    conditions,
    results,
    additionalInfos,
    pace,
    formationFundings,
    apprenticeship,
    professionalisation,
    certifying,
  } = apiTraining;
  return {
    id,
    title,
    sessions,
    provider: source,
    contact,
    chips: getTrainingChips(
      formationFundings.map(({ funding }) => funding),
      { apprenticeship, professionalisation, certifying }
    ),
    trainingDescription: {
      objectives,
      description,
      conditions,
      results,
      trainingRegionCode: parseInt(contact.formationAddress.regionCode),
      additionalInfos,
      pace,
      fundings: formationFundings.map(({ funding }) => funding),
    },
  };
};

const getTrainingChips = (
  fundings: TrainingFunding[],
  {
    apprenticeship,
    professionalisation,
    certifying,
  }: { apprenticeship: boolean; professionalisation: boolean; certifying: boolean }
): { title: string; tooltip?: string }[] => {
  const chips: { title: string; tooltip?: string }[] = fundings.map((funding) => ({
    title: funding.shortLabel,
    tooltip: funding.label ?? undefined,
  }));
  if (apprenticeship) {
    chips.push({ title: 'Apprentissage' });
  }
  if (professionalisation) {
    chips.push({ title: 'Professionnalisation' });
  }
  if (certifying) {
    chips.push({ title: 'Certifiante' });
  }

  return chips;
};
