import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import type { Folder } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { crmFolderId } from '@stores/partner/partner.selectors';
import { SubscriptionWebService } from '@webservices/subscription.webservice';
import { Observable, iif, of, switchMap } from 'rxjs';

export const partnerFoldersResolver: ResolveFn<Observable<Folder[]>> = () => {
  const store = inject(Store);
  const subscriptionWebService = inject(SubscriptionWebService);

  return store
    .select(crmFolderId)
    .pipe(
      switchMap((crmFolderId) => iif(() => !!crmFolderId, subscriptionWebService.getFoldersFor(crmFolderId!), of([])))
    );
};
