import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { ServiceConfiguration } from '@models/services/service-consent-requiring';
import { Store } from '@ngrx/store';
import { updateServiceConfiguration } from '@stores/service-configuration/service-configuration.actions';
import { serviceConfiguration } from '@stores/service-configuration/service-configuration.selectors';
import { ServiceRedirectV3WebService } from '@webservices/services/service-redirect.webservice';
import { Observable, combineLatest, filter, map } from 'rxjs';

export const serviceConsentResolver: ResolveFn<Observable<ServiceConfiguration>> = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);

  const serviceId = route.params[SERVICE_ID_ROUTE_PARAM];

  store.dispatch(updateServiceConfiguration({ serviceId }));

  return combineLatest([
    store.select(serviceConfiguration(serviceId)).pipe(filter((config): config is ServiceConfiguration => !!config)),
    inject(ServiceRedirectV3WebService).getMissingMandatoryFields(serviceId),
  ]).pipe(
    map(([serviceConfig, missingMandatoryFields]) => ({
      ...serviceConfig,
      redirection: { ...serviceConfig.redirection, missingMandatoryFields },
    }))
  );
};
