import { ObendyRoute } from '@core/app-routing';
import { FEATURES_ROUTING } from '@features/features.routing';
import { faqResolver } from '@features/resolvers/faq.resolver';

export const FAQ_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.faq,
  title: 'FAQ',
  loadComponent: () => import('@features/faq/faq.component').then((cmp) => cmp.FAQComponent),
  data: { shouldReuse: true },
  resolve: { FAQBlock: faqResolver(FEATURES_ROUTING.faq) },
  showInSiteMap: true,
};
