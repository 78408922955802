import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Child } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { childrenInfos } from '@stores/children-infos/children-infos.selector';
import { filter, map } from 'rxjs';

export const childResolver: ResolveFn<Child | null> = (route: ActivatedRouteSnapshot) => {
  return inject(Store)
    .select(childrenInfos)
    .pipe(
      filter((v): v is Child[] => v.length > 0),
      map((children) => children.filter(({ customerId }) => customerId === route.params.childId)[0] ?? null)
    );
};
