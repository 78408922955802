import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { homepageUrl } from '@stores/partner/partner.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { jwtDeleteHard } from '@wizbii-utils/angular/stores';
import { Observable, combineLatest, map, of, tap } from 'rxjs';

export const ssoConnect: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const store = inject(Store);

  const token$ = of(route.queryParamMap.get('token'));
  const homepageUrl$ = selectIfDefined(store, homepageUrl).pipe(
    map((url) => (!url.startsWith('https://') ? `https://${url}` : url))
  );

  return combineLatest([token$, homepageUrl$]).pipe(
    tap(() => store.dispatch(jwtDeleteHard())), // Dispatching an action (JwtDelete) to log out the user and clear the token
    map(([token, url]) => {
      if (token) {
        return true;
      }
      window.open(url, '_self', 'noreferrer noopener');
      return false;
    })
  );
};
