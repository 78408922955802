import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { isChild } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { map } from 'rxjs';

export const userIsAdultGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return selectIfDefined(store, isChild).pipe(
    map((isChild) => (isChild ? router.parseUrl(`/${FEATURES_ROUTING.dashboard}`) : true))
  );
};
