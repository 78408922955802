import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { isServiceAuthorized } from '@stores/profile/profile.selectors';
import { isServiceUnavailableInUserPlan } from '@stores/selectors/profile-services.selectors';
import { requestServiceAuthorizationToRedirectAction } from '@stores/service-authorization/authorization.actions';
import { ServiceAuthorizationContext } from '@stores/service-authorization/authorization.reducer';
import { serviceConfigurationConsentDisplayForm } from '@stores/service-configuration/service-configuration.selectors';
import { combineLatest, filter, map, take, tap } from 'rxjs';

export const requestServiceAuthorizationToRedirectGuard: (
  context?: Partial<ServiceAuthorizationContext>
) => CanActivateFn = (context) => (route, state) => {
  const store = inject(Store);

  const serviceId = route.data['serviceId'];
  if (!serviceId) throw Error('Missing serviceId route');

  return combineLatest([
    store
      .select(isServiceUnavailableInUserPlan(serviceId))
      .pipe(filter((serviceUnavailable) => serviceUnavailable !== undefined)),
    store.select(isServiceAuthorized(serviceId)),
    store
      .select(serviceConfigurationConsentDisplayForm(serviceId))
      .pipe(filter((displayConsents) => displayConsents !== undefined)),
  ]).pipe(
    take(1),
    map(([serviceUnavailableInUserPlan, serviceAuthorized, _]) => !serviceUnavailableInUserPlan && serviceAuthorized),
    tap((skipServiceAuthorizationToRedirectProcess) => {
      if (skipServiceAuthorizationToRedirectProcess) return;

      store.dispatch(
        requestServiceAuthorizationToRedirectAction({
          context: {
            serviceId,
            successfulExitRouterLinkOrUrl: state.url.split('/'),
            replaceUrl: true,
            ...context,
          },
        })
      );
    })
  );
};
