import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import * as FromOnboardingActions from '@stores/onboarding/onboarding.actions';
import { homepageUrl } from '@stores/partner/partner.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { OnboardingWebservice } from '@webservices/onboarding/onboarding.webservice';
import { isLogged, jwtDelete } from '@wizbii-utils/angular/stores';
import { catchError, map, of, switchMap, tap } from 'rxjs';

export const isOnboardingNotCompleted: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const onboardingWebservice = inject(OnboardingWebservice);
  const dashboardRoute = inject(Router).createUrlTree(['/', FEATURES_ROUTING.dashboard]);
  const partnerHomepageRoute$ = selectIfDefined(store, homepageUrl);

  const activationToken = route.queryParamMap.get('activation-token');
  const capsuleToken = route.queryParamMap.get('capsule-token');
  const postOnboardingRedirectRoute = route.queryParamMap.get('redirect');

  const isLoggedSig = store.selectSignal(isLogged);

  if (isLoggedSig()) {
    store.dispatch(jwtDelete());
    location.reload();
  }

  if (postOnboardingRedirectRoute !== null) {
    {
      store.dispatch(FromOnboardingActions.setRedirectRoute({ redirectRoute: postOnboardingRedirectRoute }));
    }
  }

  if (capsuleToken) {
    return onboardingWebservice.getCustomerIdFromCapsuleToken(capsuleToken).pipe(
      switchMap((customerId) => onboardingWebservice.get(customerId)),
      map((onboarding) => {
        if (onboarding.onboardingCompleted) {
          return dashboardRoute;
        }
        store.dispatch(FromOnboardingActions.setInitialState({ onboarding }));
        return true;
      })
    );
  }

  if (activationToken) {
    return onboardingWebservice.get(activationToken).pipe(
      map((onboarding) => {
        if (onboarding.onboardingCompleted) {
          return dashboardRoute;
        }
        store.dispatch(FromOnboardingActions.setInitialState({ onboarding }));
        return true;
      }),
      catchError(() =>
        partnerHomepageRoute$.pipe(
          tap((url) => {
            window.open(new URL(`https://${url}`), '_self', 'noreferrer noopener');
          }),
          map(() => false)
        )
      )
    );
  }

  return of(dashboardRoute);
};
