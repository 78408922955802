import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { platformHasUpsell } from '@stores/selectors/has-upsell.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { map } from 'rxjs';

export const platformHasUpsellGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return selectIfDefined(store, platformHasUpsell).pipe(
    map((platformHasUpsell) => (platformHasUpsell ? true : router.parseUrl(`/${FEATURES_ROUTING.notFound}`)))
  );
};
