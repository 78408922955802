import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { DashboardPage } from '@models/dashboard/dashboard.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DashboardWebservice {
  readonly #http = inject(HttpClient);

  getDashboardPage(partnerId: string): Observable<DashboardPage> {
    return this.#http.get<DashboardPage>(`${environment.api.obendy}/v1/partner/${partnerId}/page/dashboard`);
  }
}
