import { ObendyRoute } from '@core/app-routing';
import { requestServiceAuthorizationToRedirectGuard } from '@core/guards/request-authorization-to-redirect.guard';
import { canViewProgressGuard } from '@core/guards/wizbii-drive/can-view-progress.guard';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { duplicateRouteWithDifferentCanActivate } from '@core/routes/utils/route-param';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { strapiBlogAdvicesResolver } from '@domains/services/blog/resolvers/article/blog-article.resolver';
import { environment } from '@environment';
import { FEATURES_ROUTING } from '@features/features.routing';
import { serieResumeResolver } from '@features/services/drive/drive-serie-resume/resolvers/serie-resume.resolver';
import { DRIVE_ROUTING } from '@features/services/drive/drive.routing';
import {
  hasAccessToDriveMatcher,
  hasNoAccessToDriveMatcher,
} from '@features/services/drive/guards/wizbii-drive-matcher.guard';
import { lessonResolver } from '@features/services/drive/resolvers/lesson/lesson.resolver';
import { randomSerieResolver } from '@features/services/drive/resolvers/serie/random-serie.resolver';
import { serieResolver } from '@features/services/drive/resolvers/serie/serie.resolver';
import { ServiceWithManyTabsActivateGuard } from '@features/services/external-service/guards/service-with-many-tabs.guard';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { ModeEnum } from '@loyalty-v3/libs';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as driveEffects from '@stores/drive/drive.effects';
import { driveFeatureKey, driveReducer } from '@stores/drive/drive.reducer';
import { FAILED_EXIT_ROUTER_LINK_OR_URL } from '@stores/service-authorization/authorization.reducer';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';

export const WIZBII_DRIVE_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.drive,
  title: serviceTitleResolver,
  showInSiteMap: false,
  canActivate: [updateServiceConfigurationMiddleware],
  data: {
    serviceId: INTERNAL_SERVICES_IDS.drive,
  },

  loadChildren: () => [
    {
      path: '',
      showInSiteMap: false,
      providers: [provideState({ name: driveFeatureKey, reducer: driveReducer }), provideEffects(driveEffects)],
      children: [
        // Série
        {
          path: DRIVE_ROUTING.play,
          showInSiteMap: false,

          children: [
            {
              path: '',
              showInSiteMap: false,
              canActivate: [
                requestServiceAuthorizationToRedirectGuard({
                  [FAILED_EXIT_ROUTER_LINK_OR_URL]: ['/', FEATURES_ROUTING.dashboard],
                }),
              ],
              data: {
                showMenu: false,
                showFooter: false,
                mode: ModeEnum.Training,
              },
              resolve: {
                serie: randomSerieResolver,
              },
              loadComponent: () =>
                import('@features/services/drive/drive-serie-play/drive-serie-play.component').then(
                  (cmp) => cmp.DriveSeriePlayComponent
                ),
            },
            {
              path: DRIVE_ROUTING.serie,
              showInSiteMap: false,
              canActivate: [requestServiceAuthorizationToRedirectGuard()],
              data: {
                showMenu: false,
                showFooter: false,
                titlePath: 'serie.name',
              },
              resolve: {
                serie: serieResolver,
              },
              loadComponent: () =>
                import('@features/services/drive/drive-serie-play/drive-serie-play.component').then(
                  (cmp) => cmp.DriveSeriePlayComponent
                ),
            },
          ],
        },

        {
          path: `${DRIVE_ROUTING.resume}/${DRIVE_ROUTING.serie}`,
          showInSiteMap: false,
          data: {
            showMenu: false,
            showFooter: false,
            titlePath: 'serieResume.serie.name',
          },
          canActivate: [requestServiceAuthorizationToRedirectGuard()],
          resolve: {
            serieResume: serieResumeResolver,
          },
          loadComponent: () =>
            import('@features/services/drive/drive-serie-resume/drive-serie-resume.component').then(
              (cmp) => cmp.DriveSerieResumeComponent
            ),
        },

        // Résultat d'une série
        {
          path: DRIVE_ROUTING.resultSerieId,
          showInSiteMap: false,
          loadComponent: () =>
            import('@features/services/drive/drive-result/drive-result.component').then(
              (cmp) => cmp.DriveResultComponent
            ),
        },

        {
          path: '',
          showInSiteMap: false,
          loadComponent: () =>
            import('@features/services/drive/drive-layout/drive-layout.component').then(
              (cmp) => cmp.DriveLayoutComponent
            ),

          loadChildren: () => [
            {
              path: DRIVE_ROUTING.lessons,
              title: 'Cours',
              showInSiteMap: false,

              loadComponent: () =>
                import('@features/services/drive/drive-lesson-list/drive-lesson-list.component').then(
                  (cmp) => cmp.DriveLessonListComponent
                ),
            },

            {
              path: DRIVE_ROUTING.series,
              showInSiteMap: false,
              loadComponent: () =>
                import('@features/services/drive/drive-series/drive-series.component').then(
                  (cmp) => cmp.DriveSeriesComponent
                ),

              loadChildren: () => [
                {
                  path: '',
                  title: 'Séries',
                  showInSiteMap: false,
                  loadComponent: () =>
                    import(
                      '@features/services/drive/drive-series/drive-series-all-list/drive-series-all-list.component'
                    ).then((cmp) => cmp.DriveSeriesAllListComponent),
                },

                {
                  path: DRIVE_ROUTING.themeThemeId,
                  title: 'Séries',
                  showInSiteMap: false,
                  loadComponent: () =>
                    import(
                      '@features/services/drive/drive-series/drive-series-theme-list/drive-series-theme-list.component'
                    ).then((cmp) => cmp.DriveSeriesThemeListComponent),
                },
              ],
            },

            {
              path: DRIVE_ROUTING.progress,
              showInSiteMap: false,
              title: 'Progression',
              loadComponent: () =>
                import('@features/services/drive/drive-progress/drive-progress.component').then(
                  (cmp) => cmp.DriveProgressComponent
                ),
              canActivate: [canViewProgressGuard],
            },

            {
              path: DRIVE_ROUTING.advices,
              showInSiteMap: false,
              title: 'Conseils',

              loadComponent: () =>
                import('@features/services/drive/drive-advice-list/drive-advice-list.component').then(
                  (cmp) => cmp.DriveAdviceListComponent
                ),
            },

            {
              path: '',
              showInSiteMap: false,
              redirectTo: DRIVE_ROUTING.lessons,
              pathMatch: 'full',
            },
          ],
        },

        {
          path: DRIVE_ROUTING.advices,
          showInSiteMap: false,
          title: 'Conseils',
          loadChildren: () => [
            {
              path: DRIVE_ROUTING.adviceSlug,
              resolve: {
                advice: strapiBlogAdvicesResolver(environment.strapi.driveApi),
              },
              data: {
                titlePath: 'advice.title',
                algoliaArticleIndex: environment.algolia.index.wizbiiDriveAdvices,
              },
              canActivate: [requestServiceAuthorizationToRedirectGuard()],
              loadComponent: () =>
                import('@features/services/drive/drive-advice/drive-advice.component').then(
                  (cmp) => cmp.DriveAdviceComponent
                ),
              showInSiteMap: false,
            },
          ],
        },

        {
          path: DRIVE_ROUTING.lessons,
          title: 'Cours',
          showInSiteMap: false,
          loadChildren: () => [
            ...duplicateRouteWithDifferentCanActivate(
              {
                path: `${DRIVE_ROUTING.lessonId}/${DRIVE_ROUTING.chapter}/${DRIVE_ROUTING.chapterNumber}`,
                loadComponent: () =>
                  import('@features/services/drive/drive-lesson/drive-lesson.component').then(
                    (cmp) => cmp.DriveLessonComponent
                  ),
                resolve: {
                  lesson: lessonResolver,
                },
                data: {
                  titlePath: 'lesson.title',
                },
                showInSiteMap: false,
              },
              [
                { canMatch: [hasNoAccessToDriveMatcher] },
                { canMatch: [hasAccessToDriveMatcher], canActivate: [requestServiceAuthorizationToRedirectGuard()] },
              ]
            ),
            {
              path: `${DRIVE_ROUTING.lessonId}`,
              showInSiteMap: false,
              redirectTo: `${DRIVE_ROUTING.lessonId}/${DRIVE_ROUTING.chapter}/1`,
            },
          ],
        },

        {
          path: DRIVE_ROUTING.tabSlug,
          title: serviceTabTitleResolver,
          showInSiteMap: false,
          canActivate: [ServiceWithManyTabsActivateGuard],
          resolve: {
            content: TabContentFromSlugResolver,
          },
          loadComponent: async () =>
            (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
              .ServiceWithRichContentComponent,
        },
      ],
    },
  ],
};
