import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlSegment } from '@angular/router';
import { ServiceTab, ServiceTabContentTypeEnum } from '@models/services/service';
import { Store } from '@ngrx/store';
import { service } from '@stores/services/services.selector';
import { isLogged } from '@wizbii-utils/angular/stores';
import { combineLatest, filter, map } from 'rxjs';

export const ServiceWithHtml: CanMatchFn = (_route: Route, segments: UrlSegment[]) => {
  const serviceId = segments[0].path;
  const store = inject(Store);

  const hasGenericHtmlTabs = (tabs: ServiceTab[]): boolean =>
    tabs
      .map(({ contentType }) => contentType)
      .some((contentType) => contentType === ServiceTabContentTypeEnum.GenericHtml);

  return combineLatest([store.select(service(serviceId)), store.select(isLogged)]).pipe(
    filter(([service, isLogged]) => (isLogged && Boolean(service)) || !isLogged),
    map(([service, _]) => !service || hasGenericHtmlTabs(service.tabs))
  );
};
