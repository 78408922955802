import { ObendyRoute } from '@core/app-routing';
import { ConnectedRoutesGuards } from '@core/guards/connected-routes.guard';
import { displayConsentsPage } from '@core/guards/should-display-consents-page.guard';
import { ASSESS_FIRST_ROUTE } from '@core/routes/connected-routes/services-routes/assess-first.routes';
import { BIG_FIVE_ROUTES } from '@core/routes/connected-routes/services-routes/big-five.routes';
import { BLOGS_ROUTES } from '@core/routes/connected-routes/services-routes/blogs.routes';
import { EDFLEX_ROUTE } from '@core/routes/connected-routes/services-routes/edflex.routes';
import { ELVIS_ENGAGEMENT_ROUTE } from '@core/routes/connected-routes/services-routes/elvis-engagement.routes';
import { ELVIS_ROUTE } from '@core/routes/connected-routes/services-routes/elvis.routes';
import { EXTERNAL_SERVICES_ROUTES } from '@core/routes/connected-routes/services-routes/external-service.routes';
import { METIER_SCOPE_ROUTE } from '@core/routes/connected-routes/services-routes/metierscope.routes';
import { WIZBII_DRIVE_ROUTE } from '@core/routes/connected-routes/services-routes/wizbii-drive.routes';
import { WIZBII_ENGAGEMENT_ROUTE } from '@core/routes/connected-routes/services-routes/wizbii-engagement.routes';
import {
  MES_EVENEMENTS_EMPLOI_ROUTE,
  WIZBII_EVENT_ROUTE,
} from '@core/routes/connected-routes/services-routes/wizbii-events.routes';
import { WIZBII_FORMATION_ROUTE } from '@core/routes/connected-routes/services-routes/wizbii-formation.routes';
import { WIZBII_HOME_ROUTE } from '@core/routes/connected-routes/services-routes/wizbii-home.routes';
import { WIZBII_JOBS_ROUTE } from '@core/routes/connected-routes/services-routes/wizbii-jobs.routes';
import { WIZBII_METIER_ROUTE } from '@core/routes/connected-routes/services-routes/wizbii-metier.routes';
import { WIZBII_MONEY_ROUTE } from '@core/routes/connected-routes/services-routes/wizbii-money.routes';
import { serviceDesignationResolver } from '@core/title-strategy/service-designation.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { ShowCallToActionCardResolver } from '@features/dashboard/resolvers/show-call-to-action.resolver';
import { FEATURES_ROUTING } from '@features/features.routing';
import { navConfigResolver } from '@features/services-catalog/resolvers/nav-config.resolver';
import { ServicesPageResolver } from '@features/services-catalog/resolvers/services-page.resolver';
import { serviceConsentResolver } from '@features/services/service-consents-page-wrapper/service-consents-page-wrapper.resolver';
import { SERVICE_CONSENT_ROUTING } from '@features/services/service-consents-page-wrapper/service-consents.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';

export const SERVICES_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.services,
  canActivate: ConnectedRoutesGuards,
  showInSiteMap: true,
  title: serviceDesignationResolver,
  resolve: {
    showCallToActionCard: ShowCallToActionCardResolver,
  },
  loadChildren: () => [
    {
      path: '',
      showInSiteMap: true,
      title: serviceDesignationResolver,
      children: [
        {
          path: '',
          loadComponent: () =>
            import('@features/services-catalog/services-catalog.component').then((cmp) => cmp.ServicesCatalogComponent),
          showInSiteMap: false,
          resolve: {
            navConfig: navConfigResolver,
            servicesPageConfig: ServicesPageResolver,
          },
        },

        {
          path: `${SERVICES_ROUTING.serviceId}/${SERVICE_CONSENT_ROUTING.consent}`,
          showInSiteMap: false,
          title: serviceTitleResolver,
          loadComponent: () =>
            import('@features/services/service-consents-page-wrapper/service-consents-page-wrapper.component').then(
              (c) => c.ServiceConsentsPageWrapperComponent
            ),
          canActivate: [displayConsentsPage],
          resolve: {
            serviceConsent: serviceConsentResolver,
          },
        },

        MES_EVENEMENTS_EMPLOI_ROUTE,
        WIZBII_EVENT_ROUTE,

        WIZBII_DRIVE_ROUTE,
        WIZBII_JOBS_ROUTE,
        WIZBII_FORMATION_ROUTE,
        WIZBII_HOME_ROUTE,

        WIZBII_METIER_ROUTE,
        METIER_SCOPE_ROUTE,

        WIZBII_MONEY_ROUTE,
        ELVIS_ROUTE,

        WIZBII_ENGAGEMENT_ROUTE,
        ELVIS_ENGAGEMENT_ROUTE,

        ...BLOGS_ROUTES,

        ...BIG_FIVE_ROUTES,
        ASSESS_FIRST_ROUTE,
        EDFLEX_ROUTE,

        ...EXTERNAL_SERVICES_ROUTES,
      ],
    },
  ],
};
