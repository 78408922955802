import { ObendyRoute } from '@core/app-routing';
import { ConnectedRoutesGuards } from '@core/guards/connected-routes.guard';
import { FEATURES_ROUTING } from '@features/features.routing';
import { configurableFieldsResolver } from '@features/profile/infos/configurable-fields.resolver';
import { LifeMomentsResolver } from '@features/profile/life-moments/life-moments.resolver';
import { partnerFoldersResolver } from '@features/profile/notifications/partner-folders.resolver';
import { userFoldersResolver } from '@features/profile/notifications/user-folders.resolver';
import { PROFILE_ROUTING } from '@features/profile/profile.routing';

export const PROFILE_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.profile,
  canActivate: ConnectedRoutesGuards,
  title: 'Mes informations',

  loadComponent: () =>
    import('@features/profile/profile-layout/profile-layout.component').then((cmp) => cmp.ProfileLayoutComponent),
  showInSiteMap: false,

  loadChildren: () => [
    {
      path: PROFILE_ROUTING.infos,
      title: 'Infos',
      loadComponent: () => import('@features/profile/infos/infos.component').then((c) => c.InfosComponent),
      resolve: { configurableFields: configurableFieldsResolver },
      showInSiteMap: false,
    },
    {
      path: PROFILE_ROUTING.notifications,
      title: 'Mes notifications',
      loadComponent: () =>
        import('@features/profile/notifications/notifications.component').then((c) => c.NotificationsComponent),
      resolve: { partnerFolders: partnerFoldersResolver, userFolders: userFoldersResolver },
      showInSiteMap: false,
    },
    {
      path: PROFILE_ROUTING.lifeMoments,
      title: 'Moments de vie',
      loadComponent: () =>
        import('@features/profile/life-moments/life-moments.component').then((c) => c.LifeMomentsComponent),
      resolve: {
        lifeMoments: LifeMomentsResolver,
      },
      showInSiteMap: false,
    },
    {
      path: '',
      redirectTo: PROFILE_ROUTING.infos,
      pathMatch: 'full',
      showInSiteMap: false,
    },
  ],
};
