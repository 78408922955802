import { ObendyRoute } from '@core/app-routing';
import { FEATURES_ROUTING } from '@features/features.routing';

export const ERROR_ROUTES: ObendyRoute[] = [
  {
    path: FEATURES_ROUTING.notAuthorized,
    title: '401',
    showInSiteMap: false,
    loadComponent: () =>
      import('@features/errors/not-authorized/not-authorized.component').then((cmp) => cmp.NotAuthorizedComponent),
    data: {
      showMenu: false,
      showFooter: false,
    },
  },

  {
    path: FEATURES_ROUTING.notFound,
    title: '404',
    showInSiteMap: false,
    loadComponent: () => import('@features/errors/not-found/not-found.component').then((cmp) => cmp.NotFoundComponent),
    data: {
      showMenu: false,
      showFooter: true,
    },
  },

  {
    path: FEATURES_ROUTING.technicalError,
    title: '500',
    showInSiteMap: false,
    loadComponent: () =>
      import('@features/errors/technical-error/technical-error.component').then((cmp) => cmp.TechnicalErrorComponent),
    data: {
      showMenu: false,
      showFooter: true,
    },
  },
];
