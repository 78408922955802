import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { isServiceAuthorized } from '@stores/profile/profile.selectors';
import { serviceConfigurationConsentDisplayForm } from '@stores/service-configuration/service-configuration.selectors';
import { combineLatest, map, take } from 'rxjs';

export const displayConsentsPage: CanActivateFn = (route) => {
  const store = inject(Store);
  const router = inject(Router);

  const serviceId = route.paramMap.get(SERVICE_ID_ROUTE_PARAM);
  if (!serviceId) return false;

  return combineLatest([
    store.select(serviceConfigurationConsentDisplayForm(serviceId)),
    store.select(isServiceAuthorized(serviceId)),
  ]).pipe(
    take(1),
    map(([serviceRequiresConsents, serviceIsAuthorized]) => {
      if (!serviceRequiresConsents || serviceIsAuthorized) {
        return router.parseUrl(`${FEATURES_ROUTING.services}/${serviceId}`);
      }

      return true;
    })
  );
};
