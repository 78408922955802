import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { MiddlewareActivateFn } from '@models/core/middleware';
import { ServiceConfiguration } from '@models/services/service-consent-requiring';
import { Store } from '@ngrx/store';
import { updateServiceConfiguration } from '@stores/service-configuration/service-configuration.actions';
import { serviceConfiguration } from '@stores/service-configuration/service-configuration.selectors';
import { Observable, filter, map } from 'rxjs';

export const updateServiceConfigurationMiddleware: MiddlewareActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<true> => {
  const store = inject(Store);

  const serviceId = route.params[SERVICE_ID_ROUTE_PARAM] ?? (route.data.serviceId as string);
  if (!serviceId) throw new Error('Could not get serviceId in route');

  store.dispatch(updateServiceConfiguration({ serviceId }));

  return store.select(serviceConfiguration(serviceId)).pipe(
    filter((config): config is ServiceConfiguration => !!config),
    map(() => true)
  );
};
