import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { LOGIN_PASSWORD, REDIRECT_BUTTON } from '@models/partner/sign-in-method/sign-in-method.type';
import { Store } from '@ngrx/store';
import { selectPartner } from '@stores/partner/partner.selectors';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { map } from 'rxjs';

export const redirectToHomepageGuard: CanActivateFn = () => {
  const store = inject(Store);

  return selectOnceIfDefined(store, selectPartner).pipe(
    map(({ partnerConfiguration }) => {
      if (
        !partnerConfiguration.signInMethod.includes(LOGIN_PASSWORD) &&
        partnerConfiguration.signInMethod.includes(REDIRECT_BUTTON) &&
        partnerConfiguration.signInRedirectButtonLink
      ) {
        const link = partnerConfiguration.signInRedirectButtonLink.startsWith('https://')
          ? partnerConfiguration.signInRedirectButtonLink
          : `https://${partnerConfiguration.signInRedirectButtonLink}`;
        const url = new URL(link);

        window.location.href = url.href;
      }

      return true;
    })
  );
};
