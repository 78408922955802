import { ObendyRoutes } from '@core/app-routing';
import { hasPasswordTokenGuard } from '@core/guards/has-password-token.guard';
import { hasSignupGuard } from '@core/guards/has-sign-up.guard';
import { isLoggedGuard } from '@core/guards/is-logged.guard';
import { redirectToDashboardGuard } from '@core/guards/redirect-to-dashboard.guard';
import { redirectToHomepageGuard } from '@core/guards/redirect-to-homepage.guard';
import { FEATURES_ROUTING } from '@features/features.routing';
import { arePreferencesNotSet } from '@features/onboarding/guards/are-preferences-set.guard';
import { isOnboardingNotCompleted } from '@features/onboarding/guards/is-onboarding-completed.guard';
import { customerResolver } from '@features/onboarding/resolvers/customer.resolver';
import { ONBOARDING_ROUTING } from '@features/onboarding/routes';
import { SIGN_ROUTING } from '@features/sign/sign.routing';

export const LOGIN_ROUTES: ObendyRoutes = [
  {
    path: FEATURES_ROUTING.sign,
    title: 'Connexion',
    canActivate: [redirectToDashboardGuard],
    showInSiteMap: false,

    loadChildren: () => [
      {
        path: SIGN_ROUTING.signIn,
        loadComponent: () => import('@features/sign/sign-in/sign-in.component').then((c) => c.SignInComponent),
        showInSiteMap: false,
        canActivate: [redirectToHomepageGuard],
        data: {
          showMenu: false,
          showFooter: true,
        },
      },

      {
        path: SIGN_ROUTING.signInSSO,
        loadComponent: () => import('@features/sign/sign-in/sign-in.component').then((c) => c.SignInComponent),
        showInSiteMap: false,
        data: {
          showMenu: false,
          showFooter: true,
          sso: true,
        },
        canActivate: [redirectToHomepageGuard],
      },

      {
        path: SIGN_ROUTING.expiredToken,
        title: 'Lien expiré',
        showInSiteMap: false,
        loadComponent: () =>
          import('@features/sign/token-expired/token-expired.component').then((c) => c.TokenExpiredComponent),
        data: {
          showMenu: false,
          showFooter: true,
        },
      },

      {
        path: SIGN_ROUTING.tooManyAttempts,
        title: 'Oups',
        showInSiteMap: false,
        loadComponent: () =>
          import('@features/sign/too-many-attempts/too-many-attempts.component').then(
            (c) => c.TooManyAttemptsComponent
          ),
        data: {
          showMenu: false,
          showFooter: true,
        },
      },
    ],
  },

  {
    path: FEATURES_ROUTING.signUp,
    title: 'Activation',
    canActivate: [redirectToDashboardGuard, hasSignupGuard],
    loadComponent: () => import('@features/sign-up/sign-up/sign-up.component').then((c) => c.SignUpComponent),
    showInSiteMap: false,
    data: {
      showMenu: false,
      showFooter: true,
    },
  },

  {
    path: FEATURES_ROUTING.emailSent,
    title: 'Email envoyé',
    canActivate: [redirectToDashboardGuard],
    showInSiteMap: false,
    loadComponent: () => import('@features/email-sent/email-sent.component').then((m) => m.EmailSentComponent),
    data: {
      showMenu: false,
      showFooter: true,
    },
  },

  {
    path: FEATURES_ROUTING.lostPassword,
    title: 'Mot de passe oublié',
    canActivate: [redirectToDashboardGuard],
    showInSiteMap: true,
    loadComponent: () => import('@features/lost-password/lost-password.component').then((m) => m.LostPasswordComponent),
    data: {
      showMenu: false,
      showFooter: true,
      shouldReuse: true,
    },
  },

  {
    path: FEATURES_ROUTING.resetPassword,
    showInSiteMap: false,
    title: 'Réinitialiser votre mot de passe',
    canActivate: [hasPasswordTokenGuard, redirectToDashboardGuard],
    loadComponent: () =>
      import('@features/reset-password/reset-password/reset-password.component').then(
        (cmp) => cmp.ResetPasswordComponent
      ),
    data: {
      showMenu: false,
      showFooter: true,
    },
  },

  {
    path: FEATURES_ROUTING.onboarding,
    title: 'Création de compte',
    showInSiteMap: false,
    data: {
      showMenu: false,
      showFooter: true,
    },

    loadComponent: () =>
      import('@features/onboarding/onboarding-layout/onboarding-layout.component').then(
        (c) => c.OnboardingLayoutComponent
      ),

    loadChildren: () => [
      {
        path: ONBOARDING_ROUTING.infos,
        showInSiteMap: false,
        loadComponent: () =>
          import('@features/onboarding/onboarding-infos/onboarding-infos.component').then(
            (c) => c.OnboardingInfosComponent
          ),
        canActivate: [isOnboardingNotCompleted],
        resolve: {
          customer: customerResolver,
        },
      },

      {
        path: ONBOARDING_ROUTING.preferences,
        showInSiteMap: false,
        loadComponent: () =>
          import('@features/onboarding/onboarding-preferences/onboarding-preferences.component').then(
            (c) => c.OnboardingPreferencesComponent
          ),
        canActivate: [isLoggedGuard, arePreferencesNotSet],
      },

      {
        path: '',
        showInSiteMap: false,
        redirectTo: ONBOARDING_ROUTING.infos,
        pathMatch: 'full',
      },
    ],
  },

  {
    path: FEATURES_ROUTING.reactivateAccount,
    title: 'Réactivation de compte',
    showInSiteMap: false,
    loadComponent: () =>
      import('@features/reactivate-account/reactivate-account/reactivate-account.component').then(
        (cmp) => cmp.ReactivateAccountComponent
      ),
    data: {
      showMenu: false,
      showFooter: true,
    },
  },
];
