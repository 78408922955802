import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import type { EmailPreferenceV2 } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { selectProfile } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { EmailPreferenceV3WebService } from '@webservices/email-preference/email-preference.webservice';
import { Observable, switchMap } from 'rxjs';

export const userFoldersResolver: ResolveFn<Observable<EmailPreferenceV2>> = () => {
  const emailPreferenceWebService = inject(EmailPreferenceV3WebService);
  const store = inject(Store);

  return selectIfDefined(store, selectProfile).pipe(switchMap((profile) => emailPreferenceWebService.get(profile.id)));
};
