import { ObendyRoute } from '@core/app-routing';
import { isGuardianGuard } from '@core/guards/is-guardian.guard';
import { isLoggedGuard } from '@core/guards/is-logged.guard';
import { FEATURES_ROUTING } from '@features/features.routing';
import { GUARDIAN_ROUTING } from '@features/guardian/guardian.routing';
import { configurableFieldsResolver } from '@features/profile/infos/configurable-fields.resolver';
import { partnerFoldersResolver } from '@features/profile/notifications/partner-folders.resolver';
import { userFoldersResolver } from '@features/profile/notifications/user-folders.resolver';

export const GUARDIAN_ROUTE: ObendyRoute = {
  path: FEATURES_ROUTING.guardian,
  title: 'Responsable légal',
  showInSiteMap: true,
  canActivate: [isLoggedGuard, isGuardianGuard],

  loadComponent: () => import('@features/guardian/guardian/guardian.component').then((cmp) => cmp.GuardianComponent),

  loadChildren: () => [
    {
      path: '',
      title: 'Responsable légal',
      showInSiteMap: false,

      children: [
        {
          path: GUARDIAN_ROUTING.dashboard,
          showInSiteMap: true,
          title: 'Mes infos',
          loadComponent: () => import('@features/profile/infos/infos.component').then((cmp) => cmp.InfosComponent),
          resolve: {
            configurableFields: configurableFieldsResolver,
          },
        },

        {
          path: GUARDIAN_ROUTING.notifications,
          showInSiteMap: true,
          title: 'Notifications',
          loadComponent: () =>
            import('@features/profile/notifications/notifications.component').then((cmp) => cmp.NotificationsComponent),
          resolve: { partnerFolders: partnerFoldersResolver, userFolders: userFoldersResolver },
        },

        {
          path: '',
          showInSiteMap: false,
          redirectTo: GUARDIAN_ROUTING.dashboard,
          pathMatch: 'full',
        },
      ],
    },
  ],
};
