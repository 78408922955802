import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { FEATURES_ROUTING } from '@features/features.routing';
import { ServicesV3WebService } from '@webservices/services/services.webservice';
import { catchError, map, of } from 'rxjs';

export const CanAccessBenefitReservation: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const serviceId = route.params[SERVICE_ID_ROUTE_PARAM] ?? route.data.serviceId;

  const router = inject(Router);
  const serviceRoute = router.createUrlTree(['/', FEATURES_ROUTING.services, serviceId]);

  return inject(ServicesV3WebService)
    .getBenefitState(serviceId)
    .pipe(
      map(({ disabledBenefit }) => {
        if (disabledBenefit === null) return true;
        return router.parseUrl(`/${FEATURES_ROUTING.services}/${serviceId}`);
      }),
      catchError(() => of(serviceRoute))
    );
};
