import { ProductSchedule } from '@loyalty-v3/libs';

export type Service = {
  id: string;
  name: string;
  index: number | null;
  assets: ServiceAssets;
  labels: ServiceLabels;
  tabs: ServiceTab[];
  useLimitReached: boolean;
  isBenefit: boolean;
  benefitConfiguration: BenefitConfiguration | null;
};

export interface BenefitTag {
  icon: string;
  label: string;
}

export interface BenefitConfiguration {
  hasReservationPage: boolean;
  displayConfiguration: {
    tags: BenefitTag[];
    distributionSchedule: ProductSchedule | null;
  };
}

interface ServiceAssets {
  logo: string;
  mainImage: string | undefined; // NEW property
  banner: string; // formerly `twoColsPageBanner`
  icon: string;
}

interface ServiceLabels {
  purposeSentence: string; // menuBurger
  purposeShortDescription: string; // theme && menuBurger
  presentationTitle: string; // servicePresentation
  presentationDescription?: string; // servicePresentation
  fullDescription?: string; // servicePresentation V2
  catchPhrase: string; // serviceLayout content && dashboard upsell
  catchPhraseDetails: string; // serviceLayout content && dashboard upsell
  cguNotice?: string;
  redirectCta: string;
  highlightCta: string;
  disabledCta: string | null;
  disabledCtaHint: string | null;
}

export enum ServiceTabContentTypeEnum {
  GenericHtml = 'html',
  GenericBlog = 'blog',
  GenericList = 'list',
  CustomAssessFirst = 'assess_first_test',
  CustomWizbiiDriveLesson = 'wizbii_drive_lesson',
  CustomWizbiiDriveSerie = 'wizbii_drive_serie',
  CustomWizbiiDriveProgression = 'wizbii_drive_progression',
  CustomWizbiiMoneySimulation = 'wizbii_money_simulation',
  CustomWizbiiMoneyInformation = 'wizbii_money_information',
  CustomWizbiiMoneyBlogAdvice = 'wizbii_money_blog_advice',
  CustomWizbiiMoneyBlogSuggestion = 'wizbii_money_blog_suggestion',
}

export type ServiceTab = {
  id: string;
  title: string;
  slug: string;
  contentType: ServiceTabContentTypeEnum;
  disabled: boolean;
};
