import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { SERVICE_ID_ROUTE_PARAM } from '@core/routes/utils/route-param';
import { Service } from '@models/services/service';
import { Store } from '@ngrx/store';
import { service, userServices } from '@stores/services/services.selector';
import { filter, map, take } from 'rxjs';

export const serviceTitleResolver: ResolveFn<string> = (activatedRoute: ActivatedRouteSnapshot) => {
  const store = inject(Store);

  const hasServiceParams = activatedRoute.paramMap.has(SERVICE_ID_ROUTE_PARAM);
  const serviceIdFromPath = activatedRoute.url?.[0]?.path;

  if (!serviceIdFromPath && !hasServiceParams) {
    throw new Error('This resolver must be used in service route.');
  }

  if (hasServiceParams) {
    const serviceSlug = activatedRoute.paramMap.get(SERVICE_ID_ROUTE_PARAM)!;
    return store.select(userServices).pipe(
      filter((services): services is Service[] => !!services && services.length > 0),
      map((services) => services.find((s) => s.id === serviceSlug)?.name ?? ''),
      take(1)
    );
  }

  return store.select(service(serviceIdFromPath)).pipe(
    filter((serviceP): serviceP is Service => !!serviceP),
    map((serviceP) => serviceP.name),
    take(1)
  );
};
