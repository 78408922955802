import { BigFiveResult } from '@models/big-five/big-five-result';
import { createReducer, on } from '@ngrx/store';
import { loadBigFiveResultsSuccess } from '@stores/big-five/big-five.actions';

export const bigFiveFeatureKey = 'bigFive';

export interface BigFiveState {
  results: BigFiveResult[] | null;
}

const initialState: BigFiveState = {
  results: null,
};

export const bigFiveReducer = createReducer(
  initialState,
  on(loadBigFiveResultsSuccess, (state, { results }) => ({ ...state, results }))
);
