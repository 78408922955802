import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Serie } from '@models/drive';
import { SerieWebservice } from '@webservices/drive';
import { catchNotFound } from '@wizbii-utils/angular/core';
import { Observable } from 'rxjs';

export const serieResolver: ResolveFn<Observable<Serie>> = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  return inject(SerieWebservice)
    .get(route.params.serieId)
    .pipe(catchNotFound(router, ['/', FEATURES_ROUTING.notFound]));
};
