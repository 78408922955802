import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Serie, SerieRun } from '@models/drive';
import { SerieRunWebservice, SerieWebservice } from '@webservices/drive';
import { Observable, combineLatest, map, switchMap } from 'rxjs';

export const serieResumeResolver: ResolveFn<
  Observable<{
    serie: Serie;
    serieRun: SerieRun;
  }>
> = (route: ActivatedRouteSnapshot) => {
  const serieWebservice = inject(SerieWebservice);
  const serieRun$ = inject(SerieRunWebservice).get(route.params.serieId);
  const serie$ = serieRun$.pipe(switchMap((serieRun) => serieWebservice.get(serieRun.serieId)));

  return combineLatest([serie$, serieRun$]).pipe(map(([serie, serieRun]) => ({ serie, serieRun })));
};
