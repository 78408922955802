import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { LegalStatusEnum } from '@loyalty-v3/libs';
import { Store } from '@ngrx/store';
import { selectProfile } from '@stores/profile/profile.selectors';
import { selectOnceIfDefined } from '@stores/utils/selects.operators';
import { map } from 'rxjs';

export const isNotGuardianGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return selectOnceIfDefined(store, selectProfile).pipe(
    map((profile) =>
      profile.legalStatus !== LegalStatusEnum.ADULT_LEGAL_GUARDIAN
        ? true
        : router.parseUrl(`/${FEATURES_ROUTING.guardian}`)
    )
  );
};
