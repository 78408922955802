import { ObendyRoute } from '@core/app-routing';
import { updateServiceConfigurationMiddleware } from '@core/middlewares/update-service-configuration.middleware';
import { serviceTabTitleResolver } from '@core/title-strategy/service-tab-title.resolver';
import { serviceTitleResolver } from '@core/title-strategy/service-title.resolver';
import { ServiceWithManyTabsActivateGuard } from '@features/services/external-service/guards/service-with-many-tabs.guard';
import { TabContentFromSlugResolver } from '@features/services/external-service/resolvers/tab-content-from-slug.resolver';
import { PROFESSIONS_ROUTING } from '@features/services/professions/professions.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { INTERNAL_SERVICES_IDS } from '@stores/services/model';

export const METIER_SCOPE_ROUTE: ObendyRoute = {
  path: SERVICES_ROUTING.metierScope,
  title: serviceTitleResolver,
  showInSiteMap: false,
  data: {
    serviceId: INTERNAL_SERVICES_IDS.metierScope,
  },
  canActivate: [updateServiceConfigurationMiddleware],
  loadChildren: () => [
    {
      path: PROFESSIONS_ROUTING.professions,
      showInSiteMap: false,
      loadComponent: () =>
        import('@features/services/metierscope/metierscope.component').then((cmp) => cmp.MetierscopeComponent),
    },
    {
      path: PROFESSIONS_ROUTING.tabSlug,
      title: serviceTabTitleResolver,
      showInSiteMap: false,
      canActivate: [ServiceWithManyTabsActivateGuard],
      resolve: {
        content: TabContentFromSlugResolver,
      },
      loadComponent: async () =>
        (await import('@features/services/service-with-rich-content/service-with-rich-content.component'))
          .ServiceWithRichContentComponent,
    },
    {
      path: '',
      pathMatch: 'full',
      showInSiteMap: false,
      redirectTo: PROFESSIONS_ROUTING.professions,
    },
  ],
};
