import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { DashboardPage } from '@models/dashboard/dashboard.interface';
import { Store } from '@ngrx/store';
import { partnerId } from '@stores/partner/partner.selectors';
import { DashboardWebservice } from '@webservices/dashboard/dashboard.webservice';
import { filter, switchMap } from 'rxjs';

export const DashboardPageResolver: ResolveFn<DashboardPage> = () => {
  const dashboardWebservice = inject(DashboardWebservice);
  const store = inject(Store);

  return store.select(partnerId).pipe(
    filter((partnerId): partnerId is string => !!partnerId),
    switchMap((partnerId) => dashboardWebservice.getDashboardPage(partnerId))
  );
};
