import { inject } from '@angular/core';
import { Mode, Theme } from '@models/drive';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forceUpdateDatas, init, updateSeries, updateTheme } from '@stores/drive/drive.actions';
import { mode, theme } from '@stores/drive/drive.selectors';
import { selectRouteParam } from '@stores/router/router.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { SerieRunWebservice, ThemeWebservice } from '@webservices/drive';
import { info } from '@wizbii-utils/angular/stores';
import { OperatorFunction, combineLatest, distinctUntilChanged, filter, iif, map, switchMap } from 'rxjs';

export const loadDrive = createEffect(
  (themeWebservice = inject(ThemeWebservice), store = inject(Store)) =>
    selectIfDefined(store, info).pipe(
      switchMap(() => themeWebservice.find()),
      map((themes) => init({ themes }))
    ),
  { functional: true }
);

export const routerUpdate = createEffect(
  (themeWebservice = inject(ThemeWebservice), store = inject(Store)) =>
    store.select(selectRouteParam('themeId')).pipe(
      filter((themeId): themeId is string => !!themeId),
      distinctUntilChanged(),
      switchMap((themeId) => themeWebservice.get(themeId ?? '')),
      map((theme) => updateTheme({ theme }))
    ),
  { functional: true }
);

export const forceReloadDatas = createEffect(
  (actions$ = inject(Actions), store = inject(Store), serieRunWebservice = inject(SerieRunWebservice)) =>
    actions$.pipe(
      ofType(forceUpdateDatas),
      map((action) => action.isInThemeListSeries),
      filter((isInThemeListSeries): isInThemeListSeries is boolean => isInThemeListSeries !== undefined),
      switchMap((isInThemeListSeries) =>
        iif(
          () => isInThemeListSeries,
          combineLatest([store.select(mode), store.select(theme)]).pipe(
            filter(([_, theme]) => !!theme) as OperatorFunction<[Mode, Theme | undefined], [Mode, Theme]>,
            switchMap(([mode, theme]) => serieRunWebservice.findThematic(mode, theme.id))
          ),
          store.select(mode).pipe(switchMap((mode) => serieRunWebservice.findOfficial(mode)))
        )
      ),
      map((series) => updateSeries({ series }))
    ),
  { functional: true }
);
