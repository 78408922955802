import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Store } from '@ngrx/store';
import { info } from '@wizbii-utils/angular/stores';
import { map, take } from 'rxjs';

export const isPasswordExpiredGuard: CanActivateFn = () => {
  const router = inject(Router);

  return inject(Store)
    .select(info)
    .pipe(
      take(1),
      map((token) =>
        (token as any)?.['passwordExpired'] ? router.parseUrl(`/${FEATURES_ROUTING.expiredPassword}`) : true
      )
    );
};
